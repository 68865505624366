<template>
  <div class="bottom">
    <div class="CTA">
      <div class="primary-button-wrapper">
        <PrimaryButton
            autoLayoutButtonClassName="design-component-instance-node"
            className="primary-button-instance"
            :fullWidth="true"
            icon="none"
            labelClassName="primary-button-2"
            :rounded="true"
            size="large"
            state="default"
            text="주문하기"
            :tonal="true"
            @click="switchToOptions"
        />
      </div>
      <div class="banner-countdwon">
        <p class="title-6">{{ countdownText }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import PrimaryButton from "@/components/PrimaryButton.vue";

export default defineComponent({
  name: "BottomBasic",
  components: {
    PrimaryButton,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const countdownText = ref("99일 24시 59분 00초 00 후 마감");

    const targetDate = new Date(props.deal.endDate * 1000);
    const updateCountdown = () => {
      const now = new Date();
      const difference = targetDate.getTime() - now.getTime();

      if (difference <= 0) {
        countdownText.value = "마감되었습니다.";
        clearInterval(intervalId);
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      const milliseconds = Math.floor((difference % 1000) / 10);

      countdownText.value = `${days}일 ${hours}시 ${minutes}분 ${seconds
          .toString()
          .padStart(2, '0')}초 ${milliseconds.toString().padStart(2, '0')} 후 마감`;
    };

    let intervalId: number;

    onMounted(() => {
      updateCountdown();
      intervalId = setInterval(updateCountdown, 10); // 10ms마다 업데이트
    });

    onUnmounted(() => {
      clearInterval(intervalId);
    });

    const switchToOptions = () => {
      emit("switch-to-options");
    };

    return {
      countdownText,
      switchToOptions,
    };
  },
});
</script>



<style scoped>
.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 600px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px -1px 24px #00000014;
}

.bottom .CTA {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: var(--shape-corner-large) var(--shape-corner-large) 0px 0px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--size-space-0);
  position: relative;
  width: 100%;
}

.bottom .primary-button-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: var(--size-space-400);
  position: relative;
  width: 100%;
}

.bottom .banner-countdwon {
  align-items: center;
  align-self: stretch;
  background-color: #d2ff53;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  padding: 12px 16px;
  position: relative;
  width: 100%;
}

.bottom .title-6 {
  color: #000000;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: -3px;
  margin-top: -5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
</style>
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d6e22ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "bottom" }
const _hoisted_3 = { class: "sheet" }
const _hoisted_4 = { class: "option" }
const _hoisted_5 = { class: "frame-wrapper" }
const _hoisted_6 = { class: "frame" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "div-wrapper" }
const _hoisted_9 = ["aria-expanded"]
const _hoisted_10 = { class: "div" }
const _hoisted_11 = { class: "assets-select-field with-border" }
const _hoisted_12 = { class: "user-text" }
const _hoisted_13 = {
  key: 0,
  class: "assets-select-list"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "user-text-list" }
const _hoisted_16 = {
  key: 0,
  class: "check",
  src: "/img/check-1.svg",
  alt: "check"
}
const _hoisted_17 = { class: "assets-select-field-2" }
const _hoisted_18 = { class: "frame-2" }
const _hoisted_19 = { class: "text-wrapper" }
const _hoisted_20 = { class: "frame-2" }
const _hoisted_21 = { class: "frame-3" }
const _hoisted_22 = { class: "frame-4" }
const _hoisted_23 = { class: "frame-5" }
const _hoisted_24 = { class: "frame-6" }
const _hoisted_25 = { class: "frame-5" }
const _hoisted_26 = { class: "placeholder-label" }
const _hoisted_27 = { class: "frame-4" }
const _hoisted_28 = { class: "frame-8" }
const _hoisted_29 = { class: "title" }
const _hoisted_30 = { class: "price" }
const _hoisted_31 = { class: "CTA" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDown1 = _resolveComponent("ChevronDown1")!
  const _component_XCircle = _resolveComponent("XCircle")!
  const _component_Minus = _resolveComponent("Minus")!
  const _component_Plus = _resolveComponent("Plus")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_custom_bottom_sheet = _resolveComponent("custom-bottom-sheet")!

  return (_ctx.isSheetVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_custom_bottom_sheet, {
          ref: "myBottomSheet",
          title: `옵션 선택`,
          "can-swipe": false,
          overlay: true,
          "overlay-click-close": true,
          "transition-duration": 0.2,
          "max-width": 600,
          onClosed: _ctx.switchToBasic
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.deal.name), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", {
                      class: "select",
                      "aria-expanded": _ctx.isSelectOpen,
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSelect && _ctx.toggleSelect(...args)))
                    }, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.deal.dealOptions[0].optionGroupName), 1),
                          _createVNode(_component_ChevronDown1, {
                            className: _ctx.isSelectOpen ? 'rotated' : ''
                          }, null, 8, ["className"])
                        ]),
                        (_ctx.isSelectOpen)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deal.dealOptions, (option, index) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: option.optionId,
                                  class: _normalizeClass(['assets-select-field', { 'with-border': index !== _ctx.deal.dealOptions.length - 1 }]),
                                  onClick: _withModifiers(($event: any) => (_ctx.selectOption(option)), ["stop"])
                                }, [
                                  _createElementVNode("p", _hoisted_15, [
                                    (_ctx.isSelected(option))
                                      ? (_openBlock(), _createElementBlock("img", _hoisted_16))
                                      : _createCommentVNode("", true),
                                    _createTextVNode(" " + _toDisplayString(option.stockQuantity <= 0 ? '[품절!!] ' + option.optionGroupName : option.optionGroupName), 1)
                                  ])
                                ], 10, _hoisted_14))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ], 8, _hoisted_9)
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedOptions, (option) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "div-wrapper",
                      key: option.optionId
                    }, [
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("p", _hoisted_19, _toDisplayString(option.optionGroupName), 1),
                          _createVNode(_component_XCircle, {
                            class: "x-circle",
                            onClick: ($event: any) => (_ctx.removeSelectedOption(option.optionId))
                          }, null, 8, ["onClick"])
                        ]),
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, [
                              _createElementVNode("div", _hoisted_23, [
                                _createVNode(_component_Minus, {
                                  class: "minus-instance",
                                  onClick: ($event: any) => (_ctx.decreaseQuantity(option))
                                }, null, 8, ["onClick"])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_24, [
                              _createElementVNode("div", _hoisted_25, [
                                _createElementVNode("div", _hoisted_26, _toDisplayString(option.quantity), 1)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_27, [
                              _createVNode(_component_Plus, {
                                class: "icon-instance-node",
                                onClick: ($event: any) => (_ctx.increaseQuantity(option))
                              }, null, 8, ["onClick"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_28, [
                            _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.calculateDiscountRate(option)) + "%", 1),
                            _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.formatPrice(option.displayFinalPrice * option.quantity)), 1)
                          ])
                        ])
                      ])
                    ]))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _createVNode(_component_PrimaryButton, {
                    autoLayoutButtonClassName: "design-component-instance-node",
                    className: "primary-button-instance",
                    fullWidth: true,
                    icon: "none",
                    labelClassName: "primary-button-2",
                    rounded: true,
                    size: "large",
                    state: "default",
                    text: "구매하기",
                    tonal: true,
                    onClick: _ctx.switchToOrder
                  }, null, 8, ["onClick"])
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["onClosed"])
      ]))
    : _createCommentVNode("", true)
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchSm2 = _resolveComponent("SearchSm2")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['prefix-large', _ctx.className])
  }, [
    _createVNode(_component_SearchSm2, {
      class: "search-sm",
      color: "#717680"
    })
  ], 2))
}
<template>
  <div class="section">
    <div class="title">{{ order?.shipmentDetails?.shipmentName }}님</div>
    <div class="frame">
      <div class="div">
        <Label
            bold
            class="label-instance"
            div-class="design-component-instance-node"
            text="주문 번호"
            type="primary"
        />
        <div class="price">{{ order?.orderId }}</div>
      </div>
      <div class="frame-2">
        <div class="title-2">{{ statusText }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import Label from "@/components/Label.vue";
import { Order } from "@/types/Order";

export default defineComponent({
  name: "OrderViewHeadInfo",
  components: {
    Label,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  setup(props) {
    const statusText = computed(() => {
      const orderStatus = props.order?.orderStatus;
      const deliveryStatus = props.order?.deliveryDetails?.deliveryStatus;

      let paymentText = '';
      let deliveryText = '';

      // 결제 상태 처리
      if (!orderStatus) {
        paymentText = '';
      } else if (orderStatus === 'PAID_COMPLETED') {
        paymentText = '결제완료';
      } else if (orderStatus === 'ORDERED') {
        paymentText = '결제미완료';
      } else if (orderStatus === 'PAID_CANCELED') {
        paymentText = '결제취소';
      }

      // 배송 상태 처리
      if (!deliveryStatus) {
        deliveryText = '';
      } else if (deliveryStatus === 'DELIVERY_STARTED') {
        deliveryText = '배송시작';
      } else if (deliveryStatus === 'DELIVERY_COMPLETED') {
        deliveryText = '배송완료';
      }

      // 결제 상태와 배송 상태를 조합하여 하나의 문자열로 반환
      return paymentText && deliveryText ? `${paymentText} · ${deliveryText}` : paymentText || deliveryText;
    });

    return {
      statusText,
    };
  },
});
</script>

<style scoped>
.section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  padding: 24px 16px 20px;
  position: relative;
  width: 100%;
}

.section .title {
  align-self: stretch;
  color: var(--core-slate-800);
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
}

.section .frame {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.section .div {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}

.section .label-instance {
  flex: 0 0 auto !important;
}

.section .design-component-instance-node {
  color: #717680 !important;
  font-family: "Pretendard-SemiBold", Helvetica !important;
  font-size: 14px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
}

.section .price {
  color: #15181e;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.section .frame-2 {
  align-items: center;
  background-color: #d2ff53;
  border-radius: 50px;
  display: flex;
  gap: 2px;
  justify-content: center;
  padding: 4px 8px;
  position: relative;
  width: 111px;
}

.section .title-2 {
  color: var(--core-slate-800);
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
</style>

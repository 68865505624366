<template>
  <div
      :class="['action-tag', variant, stateClass]"
      @click="handleClick"
  >
    <div class="contents">
      <div :class="[labelClassName, 'div']">{{ label }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";

export default defineComponent({
  name: "ActionTag",
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    variant: {
      type: String as PropType<"filled" | "default">,
      default: "filled",
    },
    size: {
      type: String as PropType<"xlarge">,
      default: "xlarge",
    },
    state: {
      type: String as PropType<"selected" | "default">,
      default: "default",
    },
    className: {
      type: String,
      default: "",
    },
    labelClassName: {
      type: String,
      default: "",
    },
  },
  emits: ["update:selectedValue"],
  setup(props, { emit }) {
    const stateClass = computed(() => {
      return props.state === "selected" ? "selected" : "default";
    });

    const handleClick = () => {
      emit("update:selectedValue", props.value);
    };

    return {
      stateClass,
      handleClick,
    };
  },
});
</script>

<style scoped>
.action-tag {
  align-items: center;
  border-radius: var(--borders-rounded-full);
  display: inline-flex;
  justify-content: center;
  min-width: 40px;
  padding: var(--spacing-space-2);
  position: relative;
  cursor: pointer;
}

.action-tag .contents {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--spacing-space-1);
  max-width: 240px;
  padding: 0px var(--spacing-space-1);
  position: relative;
}

.action-tag .div {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  flex: 1;
  font-size: var(--label-medium-bold-font-size);
  font-style: var(--label-medium-bold-font-style);
  font-weight: var(--label-medium-bold-font-weight);
  letter-spacing: var(--label-medium-bold-letter-spacing);
  line-height: var(--label-medium-bold-line-height);
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.action-tag.selected {
  background-color: var(--colors-action-primary);
  box-shadow: var(--dropshadow-raisedbutton);
  color: var(--colors-text-inverse);
}

.action-tag.default.filled {
  background-color: var(--colors-action-primarytonal);
  color: var(--colors-text-secondary);
}

.action-tag.default {
  background-color: var(--colors-action-primarytonal);
  color: var(--colors-text-secondary);
}

.action-tag.selected .div {
  color: var(--colors-text-inverse);
}

.action-tag.default .div {
  color: var(--colors-text-secondary);
}
</style>

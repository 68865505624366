<template>
  <div :class="['outlined-textfield', className]">
    <div class="textfield">
      <input
          v-if="content === 'filled-text'"
          :class="['user-text', userTextClassName, { disabled: isDisabled }]"
          :value="modelValue"
          @input="onInput"
          :type="inputType"
          :placeholder="placeholderText || ''"
          :maxlength="maxLength"
          :disabled="isDisabled"
      />
      <template v-if="content === 'placeholder-text'">
        <template v-if="placeholderText">
          <div class="placeholder-label">{{ placeholderText }}</div>
        </template>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "OutlinedTextfield",
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: "",
    },
    userTextClassName: {
      type: String,
      default: "",
    },
    content: {
      type: String as PropType<"filled-text" | "placeholder-text">,
      default: "filled-text",
    },
    placeholderText: {
      type: String,
      default: "", // 기본값을 빈 문자열로 설정
    },
    inputType: {
      type: String,
      default: "text",  // 기본값을 "text"로 설정
    },
    maxLength: {
      type: Number,
      default: 255,  // 기본 최대 길이를 255로 설정
    },
    disabled: {
      type: Boolean,
      default: false, // 기본값을 false로 설정
    },
  },
  emits: ['update:modelValue'],
  computed: {
    isDisabled() {
      return this.disabled;
    }
  },
  methods: {
    onInput(event: Event) {
      const input = event.target as HTMLInputElement;
      this.$emit('update:modelValue', input.value);
    }
  }
});
</script>

<style scoped>
.outlined-textfield {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-space-15);
  position: relative;
  width: 310px;
}

.outlined-textfield .textfield {
  align-items: center;
  align-self: stretch;
  background-color: var(--colors-field);
  border: 1px solid;
  border-color: var(--colors-border);
  border-radius: var(--borders-rounded-medium);
  display: flex;
  height: 48px;
  min-width: 80px;
  overflow: hidden;
  padding: var(--spacing-space-3) var(--spacing-space-4) var(--spacing-space-3) var(--spacing-space-4);
  position: relative;
  width: 100%;
}

.outlined-textfield .textfield:focus-within {
  border: 2px solid;
  border-color: var(--colors-focus);
}

.outlined-textfield .user-text {
  color: var(--colors-text);
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: var(--body-large-font-size);
  font-style: var(--body-large-font-style);
  font-weight: var(--body-large-font-weight);
  letter-spacing: var(--body-large-letter-spacing);
  line-height: var(--body-large-line-height);
  margin-top: -1px;
  position: relative;
  background: none;
  border: none;
  outline: none;
}

.outlined-textfield .user-text.disabled {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--colors-text-sub);
  display: -webkit-box;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.outlined-textfield .placeholder-label {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--colors-text-sub);
  display: -webkit-box;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: var(--body-medium-font-size);
  font-style: var(--body-medium-font-style);
  font-weight: var(--body-medium-font-weight);
  letter-spacing: var(--body-medium-letter-spacing);
  line-height: var(--body-medium-line-height);
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}
</style>

<template>
  <div class="product-profile">
    <div class="top-bar-name">
      <div class="frame" @click="goToHome">
        <img class="ellipse" alt="Profile" :src="deal.seller.profileImage" />
        <div class="div">
          <div class="title">{{ deal.seller.displayName || 'Zeller market' }}</div>
        </div>
      </div>
      <div class="div">
        <!-- Instagram 링크 이미지 클릭 시 Instagram 계정으로 이동 -->
        <img class="img" alt="Instagram" src="/img/frame-131248.svg" @click="openInstagram" />
        <Truck alt="Order" class="truck-instance" @click="goToOrderAuth" />
      </div>
    </div>
    <div class="carousel-wrapper">
      <CustomCarousel :deal="deal" />
    </div>
    <div class="t-itle">
      <p class="text-wrapper">{{ deal.name }}</p>
      <div class="frame-3">
        <div class="title-2">정가 {{ deal.displayOriginPrice.toLocaleString() }}원</div>
        <div class="frame-4">
          <div class="title-3">{{ calculateDiscountPercentage(deal.displayOriginPrice, deal.displayFinalPrice) }}%</div>
          <div class="title-4">{{ deal.displayFinalPrice.toLocaleString() }}원</div>
        </div>
      </div>
    </div>
    <div class="view" v-html="deal.detailContents"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from 'vue-router';
import Truck from "@/icons/Truck.vue";
import CustomBottomSheet from "@/components/CustomBottomSheet.vue";
import CustomCarousel from "@/components/CustomCarousel.vue";

export default defineComponent({
  name: "DealProductInfo",
  components: {
    CustomBottomSheet,
    Truck,
    CustomCarousel,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    const openInstagram = () => {
      const instagramUrl = props.deal.seller?.url || "https://www.instagram.com/_lalafactory_/"; // seller 의 실제 Instagram URL 사용
      window.open(instagramUrl, "_blank");
    };

    const goToOrderAuth = () => {
      window.location.href = '/order-auth';
    };

    const goToHome = () => {
      // 현재 URL 로 이동
      router.push(router.currentRoute.value.path);
    };

    const calculateDiscountPercentage = (originalPrice: number, finalPrice: number): number => {
      if (!originalPrice || !finalPrice) return 0;
      return Math.round(((originalPrice - finalPrice) / originalPrice) * 100);
    };

    return {
      openInstagram,
      goToHome,
      goToOrderAuth,
      calculateDiscountPercentage
    };
  },
});
</script>

<style scoped>
/* CSS 코드 그대로 유지 */
.product-profile {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
}

.product-profile .top-bar-name {
  align-items: center;
  background-color: var(--x-semantic-lightsurface);
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 10px 16px;
  width: 100%;
}

.product-profile .frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  cursor: pointer;
}

.product-profile .ellipse {
  height: 32px;
  position: relative;
  width: 32px;
}

.product-profile .div {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.product-profile .title {
  color: var(--core-slate-800);
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.product-profile .img {
  flex: 0 0 auto;
  position: relative;
  cursor: pointer; /* 클릭할 수 있다는 힌트를 주기 위해 커서를 변경합니다 */
}

.product-profile .truck-instance {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  max-width: 100%; /* 부모 요소에 따라 최대 너비를 100%로 설정 */
  overflow: hidden;
  margin: 0 auto; /* 중앙 정렬 */
}

/* 나머지 스타일은 그대로 유지 */
.product-profile .t-itle {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: var(--size-space-300);
  padding: var(--size-space-300) var(--size-space-400);
  width: 100%;
}

.product-profile .text-wrapper {
  color: var(--core-slate-800);
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
}

.product-profile .frame-3 {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 93px;
}

.product-profile .title-2 {
  color: var(--core-slate-300);
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: line-through;
}

.product-profile .frame-4 {
  display: flex;
  gap: var(--size-space-050);
  width: 100%;
}

.product-profile .title-3 {
  color: #ff3a3a;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
}

.product-profile .title-4 {
  color: var(--core-slate-800);
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;
}

.product-profile .view {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
}

.product-profile .img-4 {
  width: 100%;
  height: auto;
}

.product-profile .ellipse-2 {
  background-color: var(--core-slate-800);
  border: 1px solid;
  border-color: #ffffff99;
  border-radius: 4px;
  height: 8px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-top: -1px;
  position: relative;
  width: 8px;
}
</style>
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-95ddb980"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "frame" }
const _hoisted_4 = { class: "div" }
const _hoisted_5 = { class: "price" }
const _hoisted_6 = { class: "frame-2" }
const _hoisted_7 = { class: "title-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.order?.shipmentDetails?.shipmentName) + "님", 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Label, {
          bold: "",
          class: "label-instance",
          "div-class": "design-component-instance-node",
          text: "주문 번호",
          type: "primary"
        }),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.order?.orderId), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.statusText), 1)
      ])
    ])
  ]))
}
<template>
  <div class="checkbox-container" @click="toggleCheck">
    <div class="checkbox" :class="{ checked: isChecked }" >
      <svg v-if="isChecked" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5 3L4.6875 8.5L2.5 6" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'CheckBox',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const isChecked = ref(props.modelValue);

    watch(isChecked, (newValue) => {
      emit('update:modelValue', newValue);
    });

    const toggleCheck = () => {
      isChecked.value = !isChecked.value;
    };

    return {
      isChecked,
      toggleCheck
    };
  }
});
</script>

<style scoped>
.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  width: 16px;
  height: 16px;
  background-color: var(--colors-action-secondary);
  border-radius: 4px;
  border: 1px solid var(--colors-border-minimal);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px; /* 텍스트와의 간격 */
  transition: background-color 0.2s;
}

.checkbox.checked {
  background-color: var(--colors-action-primary);
}

.checkbox svg {
  width: 12px;
  height: 12px;
}
</style>

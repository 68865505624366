<template>
  <div v-if="isSheetVisible">
    <custom-bottom-sheet ref="myBottomSheet"
                         :title="`결제하기`"
                         :can-swipe="false"
                         :overlay="true"
                         :overlay-click-close="true"
                         :transition-duration="0.2"
                         :max-width="600" @closed="switchToBasic">
      <div class="bottom-order">
        <div class="content">
          <div class="order-form">
            <div class="order-user-input">
              <div class="sheet-2">
                <div class="option">
                  <OrderFormPaymentInfo
                      :deal="deal"
                      :selectedOptions="selectedOptions"
                  />
                  <div class="sheet-option">
                    <Label bold class="label-instance" divClassName="text-wrapper-semibold" text="이름" type="primary" />
                    <OutlinedTextfield
                        class="outlined-textfield-instance"
                        content="filled-text"
                        v-model="shipmentName"
                        :max-length="50"
                        size="large"
                        state="default"
                        input-type="text"
                        userTextClassName="outlined-textfield-2"
                    />
                    <Label bold class="label-instance" divClassName="text-wrapper-semibold" text="휴대폰번호" type="primary" />
                    <OutlinedTextfield
                        class="outlined-textfield-instance"
                        content="filled-text"
                        v-model="shipmentPhoneNumber"
                        :max-length="11"
                        size="large"
                        state="default"
                        userTextClassName="outlined-textfield-2"
                        input-type="tel"
                    />
                    <KakaoAddressSearch
                        v-model:shipmentAddress="shipmentAddress"
                        v-model:postalCode="postalCode"
                    />
                    <Label bold class="label-instance" divClassName="text-wrapper-semibold" text="상세주소" type="primary" />
                    <OutlinedTextfield
                        class="outlined-textfield-instance"
                        content="filled-text"
                        v-model="shipmentDetailedAddress"
                        :max-length="200"
                        size="large"
                        state="default"
                        input-type="text"
                        userTextClassName="outlined-textfield-2"
                    />
                  </div>
                  <div class="rectangle-2" />
                  <OrderFormPaymentMethod v-model="selectedPaymentMethod" />
                  <div class="rectangle-2" />
                  <div class="agreement-div-wrapper">
                    <CheckBox2 v-model="userAgreements">
                      <div class="label-4">구매조건 동의</div>
                    </CheckBox2>
                    <OrderFormAgreements />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="order-button-wrapper">
            <div class="cta">
              <PrimaryButton
                  autoLayoutButtonClassName="primary-button-2"
                  class="primary-button-instance"
                  :fullWidth="true"
                  icon="none"
                  labelClassName="primary-button-3"
                  :rounded="true"
                  size="large"
                  state="default"
                  :text="`${totalPrice.toLocaleString()}원 결제하기`"
                  :tonal="true"
                  @click="handlePayment"
              />
            </div>
          </div>
        </div>
      </div>
    </custom-bottom-sheet>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, nextTick } from "vue";
import axios from 'axios';
import PrimaryButton from "@/components/PrimaryButton.vue";
import OrderFormPaymentInfo from "@/screens/customer/OrderFormPaymentInfo.vue";
import OrderFormPaymentMethod from "@/screens/customer/OrderFormPaymentMethod.vue";
import OrderFormAgreements from "@/screens/customer/OrderFormAgreements.vue";
import OutlinedTextfield from "@/components/OutlinedTextfield.vue";
import PrefixLarge from "@/components/PrefixLarge.vue";
import Label from "@/components/Label.vue";
import { DealOption } from "@/types/DealOption";
import { API_BASE_URL, NP_CLIENT_ID, NP_REAL_CLIENT_ID } from "@/config";
import CustomBottomSheet from '@/components/CustomBottomSheet.vue';
import "@webzlodimir/vue-bottom-sheet/dist/style.css";
import CheckBox2 from "@/components/CheckBox2.vue";
import KakaoAddressSearch from "@/screens/customer/KakaoAddressSearch.vue";

export default defineComponent({
  name: "BottomOrder",
  components: {
    KakaoAddressSearch,
    CheckBox2,
    CustomBottomSheet,
    Label, PrefixLarge, OutlinedTextfield,
    OrderFormAgreements, OrderFormPaymentMethod, OrderFormPaymentInfo,
    PrimaryButton,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
    selectedOptions: {
      type: Array as () => DealOption[],
      required: true,
    }
  },
  setup(props, { emit }) {
    const myBottomSheet = ref<InstanceType<typeof CustomBottomSheet> | null>(null);
    const isSheetVisible = ref(false);

    const selectedPaymentMethod = ref('naverpayCard');
    const shipmentName = ref(''); // 기본값
    const shipmentPhoneNumber = ref(''); // 기본값
    const shipmentAddress = ref(''); // 기본값
    const shipmentDetailedAddress = ref(''); // 기본값
    const postalCode = ref(''); // 기본값
    const userAgreements = ref(false);

    // 총 결제 금액 계산
    const totalPrice = computed(() => {
      return props.selectedOptions.reduce((total, option) => {
        return total + option.displayFinalPrice * option.quantity;
      }, 0);
    });

    const deliveryFee = props.deal.deliveryFee || 0; // 배송비가 없을 경우 0으로 설정

    const saveToLocalStorage = () => {
      const orderData = {
        shipmentName: shipmentName.value,
        shipmentPhoneNumber: shipmentPhoneNumber.value,
        shipmentAddress: shipmentAddress.value,
        shipmentDetailedAddress: shipmentDetailedAddress.value,
        postalCode: postalCode.value,
        selectedPaymentMethod: selectedPaymentMethod.value,
      };
      localStorage.setItem('orderData', JSON.stringify(orderData));
    };

    const loadFromLocalStorage = () => {
      const savedData = localStorage.getItem('orderData');
      if (savedData) {
        const parsedData = JSON.parse(savedData);
        shipmentName.value = parsedData.shipmentName || '';
        shipmentPhoneNumber.value = parsedData.shipmentPhoneNumber || '';
        shipmentAddress.value = parsedData.shipmentAddress || '';
        shipmentDetailedAddress.value = parsedData.shipmentDetailedAddress || '';
        postalCode.value = parsedData.postalCode || '';
        selectedPaymentMethod.value = parsedData.selectedPaymentMethod || 'naverpayCard';
      }
    };

    onMounted(() => {
      console.log("BottomOrder mounted...");
      loadFromLocalStorage(); // Load data from localStorage when the component mounts
      isSheetVisible.value = true;
      nextTick(() => {
        if (myBottomSheet.value) {
          myBottomSheet.value.open(); // BottomSheet open method called after the sheet is rendered
        } else {
          console.error("VueBottomSheet is not available.");
        }
      });
    });

    return {
      selectedPaymentMethod,
      shipmentName,
      shipmentPhoneNumber,
      shipmentAddress,
      shipmentDetailedAddress,
      postalCode,
      userAgreements,
      totalPrice,
      deliveryFee,
      myBottomSheet,
      isSheetVisible,
      saveToLocalStorage,
    };
  },
  methods: {
    switchToBasic() {
      this.$emit("switch-to-basic");
    },
    async handlePayment() {
      console.log("PrimaryButton clicked, initiating payment...");

      // 필수 입력값 체크
      const requiredFields = [
        { value: this.shipmentName, message: "이름을 입력해 주세요." },
        { value: this.shipmentPhoneNumber, message: "휴대폰번호를 입력해 주세요." },
        { value: this.shipmentAddress, message: "주소를 입력해 주세요." },
        { value: this.shipmentDetailedAddress, message: "상세주소를 입력해 주세요." },
        { value: this.userAgreements, message: "구매조건 및 개인정보 수집에 동의해 주세요." },
      ];

      const isValid = requiredFields.every(field => {
        if (!field.value) {
          alert(field.message);
          return false;
        }
        return true;
      });

      if (!isValid) return;

      this.saveToLocalStorage(); // Save data to localStorage before proceeding with payment

      const paymentMethod = this.selectedPaymentMethod;
      const dealId = this.deal.dealId;
      const purchaseProductName = this.deal.name;
      const resultPrice = this.totalPrice;
      const vbankHolder = this.shipmentName;
      const phoneNumber = this.shipmentPhoneNumber;
      const address = this.shipmentAddress;
      const detailedAddress = this.shipmentDetailedAddress;
      const postalCode = this.postalCode;
      const isDigital = false;
      const directReceiptType = "TypeA";
      const directReceiptNo = "1234567890";
      let cardQuota = "00";
      if (resultPrice >= 50000.0) { // 5만원 이상 결제 시 할부 가능
        cardQuota = "00,02,03,06,12";
      }
      // 결제후 나이스페이 모듈에서 그대로 포워딩 해주는 값들
      const mallReserved = "{'url':'" + window.location.href + "'}";

      // 현재 URL 이 "/10000018"인지 확인하여 적절한 clientId 선택
      const currentUrl = window.location.pathname;
      const clientId = currentUrl === "/10000018" ? NP_CLIENT_ID : NP_REAL_CLIENT_ID;

      try {
        const response = await axios.post(`${API_BASE_URL}/api/orders`, {
          phoneNumber: phoneNumber,
          dealId: dealId,
          paymentAmount: resultPrice,
          paymentMethod: paymentMethod,
          orderDetails: {
            options: this.selectedOptions.map(option => ({
              optionId: option.optionId,
              quantity: option.quantity,
              unitPrice: option.displayFinalPrice,
              totalPrice: option.displayFinalPrice * option.quantity,
              selectedOptionName: [option.optionGroupName, option.optionSubName, option.optionName].filter(Boolean).join(" "),
            })),
            deliveryFee: this.deliveryFee, // 배송비 추가
          },
          shipmentDetails: {
            shipmentName: this.shipmentName,
            address: address,
            detailedAddress: detailedAddress,
            postalCode: postalCode
          }
        });

        const orderId = response.data.orderId;
        console.log("Order :", response.data);

        if (typeof AUTHNICE !== "undefined") {
          AUTHNICE.requestPay({
            clientId: clientId,
            method: paymentMethod,
            orderId: orderId,
            amount: resultPrice,
            goodsName: purchaseProductName,
            returnUrl: `${API_BASE_URL}/nicepay/payment-result`,
            fnError: function (result: { errorMsg: string }) {
              alert("결제취소 : " + result.errorMsg);
            },
            vbankHolder: vbankHolder,
            buyerName : vbankHolder,
            buyerTel: phoneNumber,
            isDigital: isDigital,
            cardQuota: cardQuota,
            directReceiptType: directReceiptType,
            directReceiptNo: directReceiptNo,
            mallReserved: mallReserved,
          });
        } else {
          console.error("AUTHNICE is not defined. Make sure the script has loaded correctly.");
        }
      } catch (error) {
        console.error("Order creation failed:", error);
        alert("주문 생성에 실패했습니다. 잠시 후 다시 시도해 주세요.");
      }
    },
  }
});
</script>

// BottomSheet override styles
<style>
.bottom-sheet__content {
  height: auto !important;
}
.bottom-sheet__draggable-thumb {
  background: var(--core-slate-100) !important;
}
.bottom-sheet__main {
  overflow-y: auto !important; /* 필요 시 스크롤 가능하도록 설정 */
}
</style>

<style scoped>
.bottom-order {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.bottom-order .content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.order-button-wrapper {
  background-color: #ffffff;
  border-top: 1px solid #d9d9d9;
  padding: var(--size-space-400);
  width: 100%;
}

.order-button-wrapper .cta {
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-button-wrapper .primary-button-instance {
  width: 100% !important;
}

.order-form {
  flex: 1;
  overflow-y: auto;
  width: 100%;
}
.order-form .order-user-input {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}
.order-form .option {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: var(--size-space-200) 0;
  width: 100%;
}

.order-form .option .t-itle {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--size-space-200);
  padding: var(--size-space-300) var(--size-space-400) 0 var(--size-space-400);
  width: 100%;
}

.order-form .option .rectangle {
  background-color: var(--core-slate-100);
  border-radius: var(--shape-corner-extra-small);
  height: 4px;
  width: 40px;
}

.order-form .option .rectangle-2 {
  background-color: #f8f9fb;
  height: 6px;
  width: 100%;
}
.sheet-option {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: var(--size-space-400);
  position: relative;
  width: 100%;
}

.sheet-option .outlined-textfield-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  min-width: 80px !important;
  width: 100% !important;
}

.sheet-option .shipments-frame-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.sheet-option .outlined-textfield-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacing-space-15);
  min-width: 80px;
  position: relative;
  width: 100%;
}

.sheet-option .label-instance {
  flex: 0 0 auto !important;
}

.sheet-option .textfield-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--colors-field);
  border: 1px solid;
  border-color: var(--colors-border);
  border-radius: var(--borders-rounded-medium);
  display: flex;
  height: 48px;
  min-width: 80px;
  overflow: hidden;
  padding: var(--spacing-space-3) var(--spacing-space-4) var(--spacing-space-3) var(--spacing-space-4);
  position: relative;
  width: 100%;
}

.sheet-option .prefix-large-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
}

.sheet-option .placeholder-label-2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--colors-text-sub);
  display: -webkit-box;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.agreement-div-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--size-space-200);
  padding: var(--size-space-400) var(--size-space-400) var(--size-space-200) var(--size-space-400);
  position: relative;
  width: 100%;
}

.agreement-div-wrapper .checkbox-instance {
  flex: 0 0 auto !important;
}

.agreement-div-wrapper .checkbox-2 {
  font-family: "Pretendard-SemiBold", Helvetica !important;
  font-size: 14px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
}
</style>
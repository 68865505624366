<template>
  <div :class="['prefix-large', className]">
    <SearchSm2 class="search-sm" color="#717680" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SearchSm2 from "@/icons/SearchSm2.vue";


export default defineComponent({
  name: "PrefixLarge",
  components: {
    SearchSm2
  },
  props: {
    prefixText: {
      type: String,
      default: "KRW",
    },
    type: {
      type: String,
      default: "icon",
    },
    state: {
      type: String,
      default: "default",
    },
    className: {
      type: String,
      default: "",
    },
  },
});
</script>

<style scoped>
.prefix-large {
  align-items: center;
  display: inline-flex;
  max-height: 32px;
  padding: var(--spacing-space-05) var(--spacing-space-2) var(--spacing-space-05) 0px;
  position: relative;
}

.prefix-large .search-sm {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}
</style>
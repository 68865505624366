<template>
  <div class="group-section">
    <div class="section-2">
      <Label
          bold
          class="label-2"
          div-class="label-3"
          text="주문 상품"
          type="primary"
      />
      <div class="frame-3">
        <a :href="dealLink" target="_blank" rel="noopener noreferrer">
          <img class="img" alt="Img" :src="deal?.dealImages?.thumb[0]?.url" />
        </a>
        <div class="frame-4">
          <a :href="dealLink" target="_blank" rel="noopener noreferrer">
            <p class="user-text">{{ deal?.name }}</p>
          </a>
          <div class="frame-5" v-for="option in order?.orderDetails?.options" :key="option.optionId">
            <p class="user-text-option">옵션 {{ option.selectedOptionName }}</p>
            <div class="user-text-quantity">{{ option.quantity }}개</div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-2">
      <div class="div-2">
        <Label
            bold
            class="label-2"
            div-class="label-3"
            text="총 결제금액"
            type="primary"
        />
        <div class="user-text-3">{{ order?.paymentAmount?.toLocaleString() }}원</div>
      </div>
      <div class="frame-wrapper">
        <div class="frame-4">
          <div class="div-2">
            <Label
                :bold="false"
                class="label-2"
                div-class="label-4"
                text="상품금액"
                type="primary"
            />
            <div class="user-text-4">{{ totalProductPrice.toLocaleString() }}원</div>
          </div>
          <div class="div-2">
            <Label
                :bold="false"
                class="label-2"
                div-class="label-4"
                text="배송비"
                type="primary"
            />
            <div class="user-text-4">{{ deliveryFeeText }}</div>
          </div>
        </div>
      </div>
      <div class="rectangle" />
      <div class="frame-wrapper">
        <div class="frame-4">
          <div class="div-2">
            <Label
                :bold="false"
                class="label-2"
                div-class="label-4"
                text="결제방법"
                type="primary"
            />
            <div class="user-text-4">{{ order?.paymentMethod }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-2">
      <div class="labe-button">
        <Label
            bold
            class="label-2"
            div-class="label-3"
            text="받으시는 분"
            type="primary"
        />
      </div>
      <div class="frame-6">
        <div class="user-text">{{ order?.shipmentDetails?.shipmentName }}</div>
        <div class="user-text-5">{{ order?.phoneNumber }}</div>
        <p class="p">{{ order?.shipmentDetails?.address }}, {{ order?.shipmentDetails?.detailedAddress }}</p>
      </div>
      <div class="rectangle" />
      <div class="view">
        <img class="icon" alt="Icon" src="/img/icon.svg" />
        <a href="#" @click.prevent="openTrackingLink" class="user-text-6">{{ estimatedArrivalDate }}</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import Label from "@/components/Label.vue";
import { Order } from "@/types/Order";
import { Deal } from "@/types/Deal";

export default defineComponent({
  name: "OrderViewBodyInfo",
  components: {
    Label,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    deal: {
      type: Object as PropType<Deal | null>,
      required: true,
    },
  },
  setup(props) {
    const estimatedArrivalDate = computed(() => {
      if (!props.order?.createdAt) return "";

      const createdAtDate = new Date(props.order.createdAt * 1000);
      const deliveryDays = props.deal?.averageDeliveryDay || 3;
      createdAtDate.setDate(createdAtDate.getDate() + deliveryDays);

      const options: Intl.DateTimeFormatOptions = { month: "long", day: "numeric" };
      return `${createdAtDate.toLocaleDateString("ko-KR", options)} 도착예정`;
    });

    // 배송비 텍스트 계산
    const deliveryFeeText = computed(() => {
      if (
          props.deal?.deliveryFee === null ||
          props?.deal?.deliveryFee === 0 ||
          props?.deal?.deliveryFee === undefined
      ) {
        return "무료";
      } else {
        return `${props.deal.deliveryFee?.toLocaleString()}원`;
      }
    });

    const totalProductPrice = computed(() => {
      return (
          props.order?.orderDetails?.options.reduce((total, option) => total + option.totalPrice, 0) ||
          0
      );
    });

    const dealLink = computed(() => {
      if (props.deal && props.deal.seller?.subDomainName) {
        return `https://${props.deal.seller.subDomainName}.zeller.store/${props.deal.dealId}`;
      }
      return "#";
    });

    const openTrackingLink = () => {
      const trackingNumber = props.order?.trackingNumber;

      if (trackingNumber) {
        const trackingUrl = `https://trace.cjlogistics.com/web/detail.jsp?slipno=${trackingNumber}`;
        window.open(trackingUrl, "_blank", "noopener,noreferrer");
      } else {
        alert("아직 송장번호가 등록되지 않았습니다. 송장번호 등록 후 확인 가능합니다.");
      }
    };

    return {
      estimatedArrivalDate,
      deliveryFeeText,
      totalProductPrice,
      dealLink,
      openTrackingLink,
    };
  },
});
</script>



<style scoped>
.group-section {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 0px 16px;
  position: relative;
  width: 100%;
}

.group-section .section-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #f8f9fb;
  border-radius: var(--shape-corner-small);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: var(--size-space-400);
  position: relative;
  width: 100%;
}

.group-section .label-2 {
  flex: 0 0 auto !important;
}

.group-section .label-3 {
  color: #717680 !important;
  font-family: "Pretendard-SemiBold", Helvetica !important;
  font-size: 14px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
}

.group-section .frame-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 100%;
}

.group-section .img {
  height: 60px;
  position: relative;
  width: 60px;
}

.group-section .frame-4 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2px;
  justify-content: center;
  position: relative;
}

.group-section .user-text {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: #15181e;
  display: -webkit-box;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.group-section .frame-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
  width: 100%;
}

.group-section .user-text-2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #717680;
  display: -webkit-box;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

.group-section .user-text-option {
  position: relative;
  margin-top: -1px;
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: 400;
  color: #717680;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  word-wrap: break-word; /* 단어가 넘치면 줄바꿈 */
  word-break: break-all;  /* 긴 단어도 줄바꿈 */
  white-space: normal; /* 여러 줄에 걸쳐 텍스트 표시 */
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* 텍스트가 부모 컨테이너를 넘지 않도록 제한 */
  flex-grow: 1;
}

.group-section .user-text-quantity {
  position: relative;
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: 400;
  color: #717680;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  text-align: right;
  flex-shrink: 0; /* 수량 부분은 절삭되지 않도록 고정 */
}

.group-section .div-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.group-section .user-text-3 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #15181e;
  display: -webkit-box;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

.group-section .frame-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 100%;
}

.group-section .label-4 {
  color: #717680 !important;
  font-family: "Pretendard-SemiBold", Helvetica !important;
  font-size: 14px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
}

.group-section .user-text-4 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #717680;
  display: -webkit-box;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

.group-section .rectangle {
  align-self: stretch;
  background-color: #d9d9d980;
  height: 1px;
  position: relative;
  width: 100%;
}

.group-section .labe-button {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.group-section .frame-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  padding: 0px 0px 4px;
  position: relative;
  width: 100%;
}

.group-section .user-text-5 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  color: #15181e;
  display: -webkit-box;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.group-section .p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  color: #717680;
  display: -webkit-box;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.group-section .view {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6px;
  padding: 2px 0px;
  position: relative;
  width: 100%;
}

.group-section .icon {
  height: 12px;
  position: relative;
  width: 12px;
}

.group-section .user-text-6 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #15181e;
  display: -webkit-box;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

</style>
<template>
  <div v-if="isSheetVisible">
    <custom-bottom-sheet ref="myBottomSheet"
                         :title="`옵션 선택`"
                         :can-swipe="false"
                         :overlay="true"
                         :overlay-click-close="true"
                         :transition-duration="0.2"
                         :max-width="600" @closed="switchToBasic">
      <div class="bottom">
        <div class="sheet">
          <div class="option">
            <div class="frame-wrapper">
              <div class="frame">
                <p class="title">{{ deal.name }}</p>
              </div>
            </div>

            <!-- SelectBox를 옵션이 1개 이상일 때만 렌더링 -->
            <div class="div-wrapper">
              <div class="select" :aria-expanded="isSelectOpen" @click="toggleSelect">
                <div class="div">
                  <div class="assets-select-field with-border">
                    <p class="user-text">{{ deal.dealOptions[0].optionGroupName }}</p>
                    <ChevronDown1 :className="isSelectOpen ? 'rotated' : ''" />
                  </div>
                  <div v-if="isSelectOpen" class="assets-select-list">
                    <div
                        v-for="(option, index) in deal.dealOptions"
                        :key="option.optionId"
                        :class="['assets-select-field', { 'with-border': index !== deal.dealOptions.length - 1 }]"
                        @click.stop="selectOption(option)"
                    >
                      <p class="user-text-list">
                        <img v-if="isSelected(option)" class="check" src="/img/check-1.svg" alt="check" />
                        {{ option.stockQuantity <= 0 ? '[품절!!] ' + option.optionGroupName : option.optionGroupName }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="div-wrapper" v-for="option in selectedOptions" :key="option.optionId">
              <div class="assets-select-field-2">
                <div class="frame-2">
                  <p class="text-wrapper">{{ option.optionGroupName }}</p>
                  <XCircle class="x-circle" @click="removeSelectedOption(option.optionId)" />
                </div>
                <div class="frame-2">
                  <div class="frame-3">
                    <div class="frame-4">
                      <div class="frame-5">
                        <Minus class="minus-instance" @click="decreaseQuantity(option)" />
                      </div>
                    </div>
                    <div class="frame-6">
                      <div class="frame-5">
                        <div class="placeholder-label">{{ option.quantity }}</div>
                      </div>
                    </div>
                    <div class="frame-4">
                      <Plus class="icon-instance-node" @click="increaseQuantity(option)" />
                    </div>
                  </div>
                  <div class="frame-8">
                    <div class="title">{{ calculateDiscountRate(option) }}%</div>
                    <div class="price">{{ formatPrice(option.displayFinalPrice * option.quantity) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="CTA">
            <PrimaryButton
                autoLayoutButtonClassName="design-component-instance-node"
                className="primary-button-instance"
                :fullWidth="true"
                icon="none"
                labelClassName="primary-button-2"
                :rounded="true"
                size="large"
                state="default"
                text="구매하기"
                :tonal="true"
                @click="switchToOrder"
            />
          </div>
        </div>
      </div>
    </custom-bottom-sheet>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, nextTick } from "vue";
import Plus from "@/icons/Plus.vue";
import Minus from "@/icons/Minus.vue";
import XCircle from "@/icons/XCircle.vue";
import ChevronDown1 from "@/icons/ChevronDown1.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import { DealOption } from "@/types/DealOption";
import CustomBottomSheet from "@/components/CustomBottomSheet.vue";
import "@webzlodimir/vue-bottom-sheet/dist/style.css";

export default defineComponent({
  name: "BottomOptions",
  components: {
    PrimaryButton,
    ChevronDown1,
    XCircle,
    Minus,
    Plus,
    CustomBottomSheet,
  },
  props: {
    deal: {
      type: Object as () => {
        dealOptions: DealOption[];
        name: string;
      },
      required: true,
    },
  },
  setup(props, { emit }) {
    const isSelectOpen = ref(false);
    const selectedOptions = ref<DealOption[]>([]);
    const myBottomSheet = ref<InstanceType<typeof CustomBottomSheet> | null>(null);
    const isSheetVisible = ref(false);

    const selectOption = (option: DealOption) => {
      if (option.stockQuantity <= 0) {
        alert("선택한 옵션은 품절되었습니다.");
        return;
      }

      const isAlreadySelected = selectedOptions.value.some(
          (selected) => selected.optionId === option.optionId
      );

      if (!isAlreadySelected) {
        selectedOptions.value.push({ ...option, quantity: 1 });
      }

      isSelectOpen.value = false;
    };

    const formatPrice = (price: number) => {
      return new Intl.NumberFormat('ko-KR').format(price) + '원';
    };

    const calculateDiscountRate = (option: DealOption) => {
      const discount = ((option.displayOriginalPrice - option.displayFinalPrice) / option.displayOriginalPrice) * 100;
      return Math.round(discount);
    };

    onMounted(() => {
      // 자동 옵션 선택
      if (props.deal.dealOptions.length === 1) {
        selectOption(props.deal.dealOptions[0]);
      }

      isSheetVisible.value = true;
      nextTick(() => {
        if (myBottomSheet.value) {
          myBottomSheet.value.open();
        } else {
          console.error("VueBottomSheet is not available.");
        }
      });
    });

    const toggleSelect = () => {
      isSelectOpen.value = !isSelectOpen.value;
    };

    const removeSelectedOption = (optionId: number) => {
      selectedOptions.value = selectedOptions.value.filter(
          (option) => option.optionId !== optionId
      );
    };

    const increaseQuantity = (option: DealOption) => {
      if (option.quantity >= 9) {
        return;
      }
      option.quantity += 1;
    };

    const decreaseQuantity = (option: DealOption) => {
      if (option.quantity > 1) {
        option.quantity -= 1;
      }
    };

    const switchToOrder = () => {
      if (selectedOptions.value.length === 0) {
        alert("옵션을 선택해 주세요.");
        return;
      }
      emit("switch-to-order", selectedOptions.value);
    };

    return {
      isSelectOpen,
      selectedOptions,
      toggleSelect,
      selectOption,
      removeSelectedOption,
      increaseQuantity,
      decreaseQuantity,
      switchToOrder,
      formatPrice,
      calculateDiscountRate,
      myBottomSheet,
      isSheetVisible,
    };
  },
  methods: {
    switchToBasic() {
      this.$emit("switch-to-basic");
    },
    isSelected(option: DealOption) {
      return this.selectedOptions.some(
          (selected) => selected.optionId === option.optionId
      );
    },
  }
});
</script>



// BottomSheet override styles
<style>
.bottom-sheet__content {
  height: auto !important;
  border-radius: 16px 16px 0 0 !important; /* 상단 좌우 모서리에 radius 적용 */
}
.bottom-sheet__draggable-thumb {
  background: var(--core-slate-100) !important;
}
.bottom-sheet__main {
  overflow-y: auto !important; /* 필요 시 스크롤 가능하도록 설정 */
}

</style>

<style scoped>
.bottom {
  display: flex;
  flex-direction: column;
  height: auto;
}

.sheet {
  overflow: visible;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottom .option {
  overflow: visible;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 0px var(--size-space-200) 0px;
  position: relative;
  width: 100%;
  min-height: 50vh;
}

.bottom .t-itle {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--size-space-200);
  padding: var(--size-space-300) var(--size-space-400) var(--size-space-0) var(--size-space-400);
  position: relative;
  width: 100%;
}

.bottom .rectangle {
  background-color: var(--core-slate-100);
  border-radius: var(--shape-corner-extra-small);
  height: 4px;
  position: relative;
  width: 40px;
}

.bottom .frame-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row; /* Change this line */
  justify-content: space-between; /* Add this line */
  gap: var(--size-space-150); /* Adjust if needed */
  padding: var(--size-space-300) var(--size-space-400) var(--size-space-300) var(--size-space-400); /* Adjust if needed */
  position: relative;
  width: 100%;
}

.bottom .x-circle {
  margin-left: auto; /* Optional: Add this line if you need to push XCircle to the far right */
}

.bottom .frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--size-space-100);
  position: relative;
  width: 100%;
}

.bottom .title {
  align-self: stretch;
  color: var(--core-slate-800);
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
}

.bottom .div-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: var(--size-space-200) var(--size-space-400) var(--size-space-200) var(--size-space-400);
  position: relative;
  width: 100%;
}

.bottom .select {
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid;
  border-color: #d9d9d9;
  border-radius: var(--shape-corner-small);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--spacing-space-15);
  position: relative;
  width: 100%;
}

.bottom .div {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.bottom .user-text {
  color: var(--colors-text);
  flex: 1;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}

.bottom .user-text-list {
  display: flex; /* 추가 */
  align-items: center; /* 추가 */
  position: relative;
  gap: 4px;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: 400;
  color: var(--colors-text);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}


.bottom .icon-instance-node {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.bottom .assets-select-field-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #f8f9fb;
  border-radius: var(--shape-corner-small);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  padding: var(--size-space-400);
  position: relative;
  width: 100%;
}

.bottom .frame-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.bottom .text-wrapper {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #15181e;
  display: -webkit-box;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  overflow: visible;
  position: relative;
  text-overflow: ellipsis;
  width: 260px;
}

.bottom .frame-3 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d9d9d9;
  border-radius: 24px;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.bottom .frame-4 {
  align-items: center;
  display: flex;
  gap: var(--size-space-400);
  height: 40px;
  justify-content: center;
  padding: 6px 0px;
  position: relative;
  width: 40px;
}

.bottom .frame-5 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 9px;
}

.bottom .minus-instance {
  height: 16px !important;
  margin-left: -3.5px !important;
  margin-right: -3.5px !important;
  position: relative !important;
  width: 16px !important;
}

.bottom .frame-6 {
  align-items: center;
  display: flex;
  gap: var(--size-space-400);
  height: 32px;
  justify-content: center;
  padding: 6px 0px;
  position: relative;
  width: 32px;
}

.bottom .frame-8 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 2px;
  position: relative;
  flex: 0 0 auto;
}

.frame-8 .title {
  position: relative;
  width: 100%;
  margin-top: -1px;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: #ff3a3a;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
  text-align: right;
}

.bottom .placeholder-label {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: #15181e;
  display: -webkit-box;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
}

.bottom .price {
  color: var(--colors-text);
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.bottom .CTA {
  position: relative;
  bottom: 0;
  background: white;
  align-items: flex-start;
  align-self: stretch;
  border-color: #d9d9d9;
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: var(--size-space-400);
  width: 100%;
}



.bottom .assets-select-field {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: var(--spacing-space-2);
  height: 48px;
  padding: 0px 12px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.bottom .assets-select-list {
  width: 100%;
}

.bottom .check {
  position: relative;
  width: 16px;
  height: 16px;
}

.bottom .assets-select-field.with-border {
  border-bottom: 1px solid #d9d9d9;
}

.bottom .select {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

</style>
<template>
  <div class="welcome-container">
    <div class="content-wrapper">
      <img src="@/assets/logo_800.jpg" alt="Zeller Logo" class="welcome-logo" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Intro",
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Viewport Height */
  width: 100vw; /* Viewport Width to cover entire width */
  background-color: #000000; /* Ensure background is black outside the max-width */
  overflow: hidden;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px; /* Limit the content width */
  transform: translateY(-50px); /* Move the logo up by 50px */
}

.welcome-logo {
  max-width: 80%; /* Adjust size if needed */
  height: auto;
}

@media (min-width: 600px) {
  .content-wrapper {
    width: 100%;
  }
}
</style>

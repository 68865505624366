<template>
  <div :class="['primary-button', className, { 'full-width': fullWidth, 'disabled': disabled }]">
    <div :class="['auto-layout-button', state, autoLayoutButtonClassName]">
      <div :class="['label', labelClassName]">{{ text }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "PrimaryButton",
  props: {
    text: {
      type: String,
      default: "버튼",
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    tonal: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<"large">,
      default: "large",
    },
    state: {
      type: String as PropType<"hover" | "default">,
      default: "default",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String as PropType<"none">,
      default: "none",
    },
    className: {
      type: String,
      default: "",
    },
    autoLayoutButtonClassName: {
      type: String,
      default: "",
    },
    labelClassName: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  }
});
</script>

<style scoped>
.primary-button {
  width: 100%;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.primary-button.disabled {
  cursor: not-allowed;
}

.primary-button.full-width .auto-layout-button {
  width: 100%; /* 버튼이 전체 너비를 차지하도록 설정 */
}

.primary-button .auto-layout-button {
  align-items: center;
  border-radius: var(--borders-rounded-medium);
  display: inline-flex;
  flex: 0 0 auto;
  gap: var(--spacing-space-2);
  justify-content: center;
  min-width: var(--properties-button-large-minwidth);
  padding: var(--spacing-space-3) var(--spacing-space-4) var(--spacing-space-3) var(--spacing-space-4);
  position: relative;
}

.primary-button .label {
  color: var(--colors-text-inverse);
  font-family: "Pretendard-Bold", Helvetica;
  font-size: var(--label-large-bold-font-size);
  font-style: var(--label-large-bold-font-style);
  font-weight: var(--label-large-bold-font-weight);
  letter-spacing: var(--label-large-bold-letter-spacing);
  line-height: var(--label-large-bold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.primary-button .default {
  background-color: var(--colors-action-primary);
}

.primary-button .hover {
  background-color: var(--colors-action-primary-hover);
}

.primary-button.disabled .auto-layout-button {
  background-color: #f8f8f9; /* 비활성화 상태 기본 색상 */
  color: #007bff; /* 비활성화 상태 텍스트 색상 */
}
.primary-button.disabled .label {
  color: #bcc0c6;
}
</style>

<template>
  <p>취소 및 반품 주소</p>
  <p>- 서울특별시 강남구 강남대로 584 6층 6085호(논현동, 성일빌딩), 06043 / (주)플레이타임</p>
  <p>- 교환환불 문의 이메일 admin@zeller.store</p>
  <p><br></p>
  <p>당사 제품의 환불은 아래 조건에 해당되는 경우에만 가능합니다. 또한, 당사의 정책은 30일 동안 지속됩니다. 구매 후 30일이 지나면 환불이나 교환을 제공할 수 없습니다. 반품을 받으려면 제품이 사용되지 않았고 제품은 수령한 상태 (포장 포함) 과 동일한 상태이어야 합니다.&nbsp;</p>
  <p><br></p>
  <p>취소 및 반품이 가능한 경우</p>
  <p>&nbsp;- 계약내용에 관한 서면을 받은 날부터 7일. 단, 그 서면을 받은 때보다 재화등의 공급이 늦게 이루어진 경우에는 재화등을 공급받거나 재화등의 공급이 시작된 날부터 7일 이내</p>
  <p>&nbsp;- 공급받으신 상품 및 용역의 내용이 표시, 광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날 부터 7일이내</p>
  <p>&nbsp;</p>
  <p>취소 및 반품이 불가능한 경우</p>
  <p>&nbsp;- 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우</p>
  <p>&nbsp;- 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 감소한 경우 (포장 포함)&nbsp;</p>
  <p>&nbsp;- 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우</p>
  <p>&nbsp;- 복제가 가능한 재화등의 포장을 훼손한 경우</p>
  <p>&nbsp;- 개별 주문 생산되는 재화 등 청약철회시 판매자에게 회복할 수 없는 피해가 예상되어 소비자의 사전 동의를 얻은 경우</p>
  <p>※ 고객님의 마음이 바뀌어 반품을 하실 경우 상품반송 비용은 고객님께서 부담하셔야 합니다.</p>
  <p><br></p>
  <p>교환 관련 안내&nbsp;</p>
  <p>시스템상 교환이 어려운 지점이 있어 번거로우시더라도 기존 주문 건은 취소해 주시고 다시 한번 주문해 주시면 감사하겠습니다.&nbsp;</p>
  <p><br></p>
  <p>환불시 반품 확인여부를 확인한 후 15영업일 이내에 결제 금액을 환불해 드립니다.</p>
  <p>신용카드로 결제하신 경우는 신용카드 승인을 취소하여 결제 대금이 청구되지 않게 합니다.</p>
  <p>(단, 신용카드 결제일자에 맞추어 대금이 청구 될수 있으면 이경우 익월 신용카드 대금청구시 카드사에서 환급처리</p>
  <p>됩니다.)</p>
  <p><br/></p>
  <p><br/></p>
  <p><br/></p>
</template>

<style scoped>
p {
  color: var(--colors-text-sub);
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}
</style>
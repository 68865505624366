<template>
  <svg
    :class="['x-circle', className]"
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="path"
      d="M12.5001 7.50002L7.50008 12.5M7.50008 7.50002L12.5001 12.5M18.3334 10C18.3334 14.6024 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6024 1.66675 10C1.66675 5.39765 5.39771 1.66669 10.0001 1.66669C14.6025 1.66669 18.3334 5.39765 18.3334 10Z"
      stroke="#717680"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "XCircle",
  props: {
    className: {
      type: String,
      default: "",
    },
  },
});
</script>

<style scoped>
.x-circle {
  cursor: pointer;
}
</style>

<template>
  <div class="screen" v-if="order">
    <div class="content">
      <div class="top-bar-name">
        <div class="title">내 주문 확인</div>
      </div>
      <OrderViewHeadInfo :order="order" />
      <OrderViewBodyInfo :order="order" :deal="deal"/>
      <OrderViewBottomButton :order="order" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";

import OrderViewHeadInfo from "@/screens/customer/OrderViewHeadInfo.vue";
import OrderViewBodyInfo from "@/screens/customer/OrderViewBodyInfo.vue";
import OrderViewBottomButton from "@/screens/customer/OrderViewBottomButton.vue";
import { API_BASE_URL } from "@/config"; // 환경 변수
import { Deal } from "@/types/Deal"; // Deal 타입 임포트
import { Order } from "@/types/Order";

export default defineComponent({
  name: "OrderDetail",
  components: {
    OrderViewBottomButton,
    OrderViewBodyInfo,
    OrderViewHeadInfo,
  },
  setup() {
    const order = ref<Order | null>(null);
    const deal = ref<Deal | null>(null); // Deal 객체 추가
    const route = useRoute();
    const router = useRouter();

    onMounted(async () => {
      const orderId = route.query.orderId as string;
      const jwtToken = localStorage.getItem(`jwt_order_${orderId}`); // orderId 별로 저장된 JWT 토큰 가져오기

      console.log('orderId:', orderId);

      if (jwtToken) {
        try {
          // 주문 정보 조회
          const orderResponse = await axios.get(`${API_BASE_URL}/api/orders/me/info`, {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          });
          order.value = orderResponse.data; // 서버로부터 받은 주문 정보를 설정
          console.log('주문 정보:', order.value);

          // dealId를 이용해 Deal 정보 조회
          if (order.value && order.value.dealId) {
            const dealResponse = await axios.get(`${API_BASE_URL}/api/deals/${order.value.dealId}`);
            deal.value = dealResponse.data; // Deal 정보를 설정
            console.log('Deal 정보:', deal.value);
          }
        } catch (err) {
          const error = err as any; // 타입스크립트에서는 err를 any로 캐스팅
          if (error.response && error.response.status === 401) {
            alert('인증 정보가 만료되었습니다. 다시 주문정보를 입력하여 주세요.');
            await router.push({ name: 'OrderAuth' });
          } else {
            console.error('오류 발생:', error);
            alert('주문 정보를 가져오는 중 오류가 발생했습니다.');
          }
        }
      } else {
        alert('인증 정보가 만료되었습니다. 다시 주문정보를 입력하여 주세요.');
        await router.push({ name: 'OrderAuth' });
      }
    });

    return {
      order,
      deal,
    };
  },
});
</script>


<style scoped>
.screen {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  padding-top: 0;
}

.content {
  background-color: #ffffff;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.screen .top-bar-name {
  align-items: center;
  background-color: var(--x-semantic-lightsurface);
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  padding: 10px 16px;
  width: 100%;
}

.screen .title {
  color: var(--core-slate-800);
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
</style>
<template>
  <div :class="['label', className]">
    <div :class="['text-wrapper', divClassName]">{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "Label",
  props: {
    text: {
      type: String,
      default: "Label",
    },
    tooltipHelpIcon: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<"primary">,
      default: "primary",
    },
    bold: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
    divClassName: {
      type: String,
      default: "",
    },
  },
});
</script>

<style scoped>
.label {
  align-items: center;
  display: inline-flex;
  gap: var(--spacing-space-1);
  position: relative;
}

.label .text-wrapper-semibold {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: #15181e;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}
</style>
<template>
  <footer class="footer">
    <div class="frame">
      <div class="div">
        <div class="frame-2"><div class="user-text">젤러</div></div>
        <div class="frame-wrapper">
          <div class="frame-2">
            <div class="user-text">사업자 등록번호</div>
            <div class="text-wrapper">306-88-02938</div>
          </div>
        </div>
        <div class="frame-2"><div class="text-wrapper">통신판매업신고 2024-서울강남-04734</div></div>
        <p class="p">서울시 강남구 강남대로 584 6층</p>
      </div>
      <p class="user-text-2">
        젤러는 통신판매중개시스템의 제공자로 통신판매의 당사자가 아니며 상품의 주문 배송 및 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다.
      </p>
      <p class="p">문의: 카카오톡 @젤러</p>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
});
</script>

<style scoped>
@media (max-width: 600px) {
  .footer {
    width: 100%;
  }
}

.footer {
  background-color: #ffffff;
  margin: 0 auto;
  box-sizing: border-box;
  /* Add sufficient padding to the bottom */
  padding-bottom: 40px;
}

.footer .frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  padding: 19px 16px 0 16px;
}

.footer .div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.footer .frame-2 {
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}

.footer .user-text {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-Bold", Helvetica;
  font-weight: 700;
  color: #bcc0c6;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  white-space: nowrap;
}

.footer .frame-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.footer .text-wrapper {
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-Medium", Helvetica;
  font-weight: 500;
  line-height: 14px;
  white-space: nowrap;
  position: relative;
  color: #bcc0c6;
  font-size: 12px;
  letter-spacing: 0;
}

.footer .p {
  align-self: stretch;
  font-family: "Pretendard-Medium", Helvetica;
  font-weight: 500;
  line-height: 14px;
  position: relative;
  color: #bcc0c6;
  font-size: 12px;
  letter-spacing: 0;
}

.footer .user-text-2 {
  align-self: stretch;
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: 400;
  line-height: 16px;
  position: relative;
  color: #bcc0c6;
  font-size: 12px;
  letter-spacing: 0;
}
</style>
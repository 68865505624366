<template>
  <div class="screen">
    <div class="content">
      <SellerProfileSection />
      <SellerFrameWrapper />
      <div class="bottom-a">
        <div class="CTA">
          <div class="frame-17">
            <p class="title-5">
              <span class="text-wrapper-3">12,345명의 인스타 셀러 </span>
              <span class="text-wrapper-4">가입 대기중</span>
            </p>
            <div class="primary-button-wrapper">
              <PrimaryButton
                  autoLayoutButtonClassName="design-component-instance-node"
                  className="primary-button-instance"
                  :fullWidth="true"
                  icon="none"
                  labelClassName="primary-button-2"
                  :rounded="true"
                  size="large"
                  state="default"
                  text="내 스토어 오픈하기"
                  :tonal="true"
                  @click="openAskingForm"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- CustomBottomSheet 추가 -->
    <custom-bottom-sheet ref="askingFormSheet"
                         :title="'셀러 가입 문의'"
                         :can-swipe="false"
                         :overlay="true"
                         :overlay-click-close="true"
                         :transition-duration="0.2"
                         :max-width="600">
      <SellerAskingForm @close-sheet="closeAskingForm" />
    </custom-bottom-sheet>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import SellerFrameWrapper from "./seller/SellerFrameWrapper.vue";
import SellerProfileSection from "./seller/SellerProfileSection.vue";
import SellerAskingForm from "./seller/SellerAskingForm.vue"; // SellerAskingForm import
import CustomBottomSheet from "@/components/CustomBottomSheet.vue"; // CustomBottomSheet import
import PrimaryButton from "@/components/PrimaryButton.vue";

export default defineComponent({
  name: "SellerLanding",
  components: {
    PrimaryButton,
    SellerFrameWrapper,
    SellerProfileSection,
    SellerAskingForm,
    CustomBottomSheet,
  },
  setup() {
    const askingFormSheet = ref<InstanceType<typeof CustomBottomSheet> | null>(null);

    const openAskingForm = () => {
      if (askingFormSheet.value) {
        askingFormSheet.value.open();
      }
    };

    const closeAskingForm = () => {
      if (askingFormSheet.value) {
        askingFormSheet.value.close();
      }
    };

    return {
      openAskingForm,
      closeAskingForm,
      askingFormSheet,
    };
  },
});
</script>

<style scoped>
.screen {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 0;
  position: relative; /* 부모 컨텍스트 설정 */
}

.content {
  background-color: #ffffff;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding-bottom: 120px;
}

.bottom-a {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px -1px 24px #00000014;
  border-radius: var(--shape-corner-large) var(--shape-corner-large) 0px 0px;
  gap: var(--size-space-0);
}

.bottom-a .CTA {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #ffffff;
}

.bottom-a .frame-17 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: var(--size-space-400) var(--size-space-400) 16px var(--size-space-400);
  width: 100%;
}


.bottom-a .title-5 {
  font-family: "Pretendard-SemiBold", Helvetica;
  width: fit-content;
  margin-top: -1px;
  font-size: 14px;
  letter-spacing: 0;
  font-weight: 600;
  line-height: 20px;
  color: #15181e;
  text-align: center;
  white-space: nowrap;
}

.bottom-a .text-wrapper-3 {
  color: #ff238c;
}

.bottom-a .text-wrapper-4 {
  color: #15181e;
}

.bottom-a .primary-button-instance {
  display: flex;
  width: 100%;
}

.primary-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

</style>

import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['primary-button', _ctx.className, { 'full-width': _ctx.fullWidth, 'disabled': _ctx.disabled }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['auto-layout-button', _ctx.state, _ctx.autoLayoutButtonClassName])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(['label', _ctx.labelClassName])
      }, _toDisplayString(_ctx.text), 3)
    ], 2)
  ], 2))
}
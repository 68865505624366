<template>
  <carousel :items-to-show="1" :wrap-around="true">
    <Slide v-for="(image, index) in deal.dealImages.list" :key="index">
      <img :src="image.url" :alt="image.description || 'Carousel Image'" class="carousel-image" />
    </Slide>
    <template #addons>
      <pagination class="custom-pagination" />
    </template>
  </carousel>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default defineComponent({
  name: "CarouselComponent",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
});
</script>
// carousel style override. NOT in scoped
<style>
.carousel-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 위에 작성한 커스텀 페이지네이션 CSS 코드 */
/* 기본 페이지네이션 스타일 오버라이드 */
.carousel__pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  line-height: 0;
  margin: 10px 0 0;
  padding: 0;
  position: absolute;
  bottom: 20px; /* Carousel 이미지 하단에서 20px 위에 위치 */
  left: 50%;
  transform: translateX(-50%);
}

.carousel__pagination-button {
  display: block;
  border: 0;
  margin: 0 3px; /* 간격 조정 */
  cursor: pointer;
  padding: 0;
  background: transparent;
}

.carousel__pagination-button::after {
  display: block;
  content: '';
  width: 8px; /* 커스텀 너비 */
  height: 8px; /* 커스텀 높이 */
  border-radius: 4px; /* 커스텀 경계 반경 */
  background-color: #00000033; /* 비활성화 상태의 배경 색상 */
  border: 1px solid #ffffff99; /* 테두리 색상 */
}

.carousel__pagination-button--active::after {
  background-color: #1d2027b2; /* 활성화된 상태의 배경 색상 */
}

/* Hover 상태 스타일 */
@media(hover: hover) {
  .carousel__pagination-button:hover::after {
    background-color: #1d2027b2; /* Hover 상태에서 활성화된 색상과 동일하게 변경 */
  }
}

</style>

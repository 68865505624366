<template>
  <div class="frame">
    <div class="element">
      <div class="title">
        <div class="user-text">공구 맞춤형 판매 링크</div>
        <p class="text-wrapper">
          자사몰 노출 없는 공구 페이지<br />네이버페이, 카카오페이, 신용카드, 현금결제<br />모두 지원 공구 일정에 따라
          자동 on/off 돼요
        </p>
      </div>
      <img class="img" src="/img/brand-img1.png"  alt="img1"/>
    </div>
    <div class="element">
      <div class="title">
        <div class="user-text">판매 실적 자동 리포팅</div>
        <p class="text-wrapper">공구 셀러별 판매 실적 자동 집계<br />총 판매내역 &amp; 커미션 자동 계산</p>
      </div>
      <img class="img" src="/img/brand-img2.png"  alt="img2"/>
    </div>
    <div class="element">
      <div class="div">
        <div class="user-text">정산 자동화</div>
        <p class="text-wrapper">
          다건 수동 정산, 더이상 스트레스 받지 마세요<br />젤러가 셀러별 커미션을 자동 정산합니다
        </p>
      </div>
      <img class="img" src="/img/brand-img3.png"  alt="img3"/>
    </div>
    <div class="element">
      <div class="div">
        <div class="user-text">대량 섭외도 간편하게</div>
        <p class="text-wrapper">피곤한 DM에서 벗어나세요<br />젤러가 섭외를 도와드려요</p>
      </div>
      <img class="img" src="/img/brand-img4.png"  alt="img4"/>
    </div>
    <div class="frame-wrapper">
      <div class="div-2">
        <img class="master-circle" src="/img/circle-warning.svg"  alt="img4"/>
        <p class="p">공구, 배송만 신경쓰세요<br />나머지는 젤러가 해결할게요</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BrandFrameWrapper",
  components: {
  },
});
</script>

<style scoped>
.frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 48px;
  position: relative;
  padding-bottom: 60px;
}

.frame .element {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.frame .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.frame .user-text {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: "Pretendard-Bold", Helvetica;
  font-weight: 700;
  color: #15181e;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.frame .text-wrapper {
  font-family: "Pretendard-Medium", Helvetica;
  font-weight: 500;
  color: #4b515b;
  font-size: 15px;
  line-height: 26px;
  position: relative;
  align-self: stretch;
  text-align: center;
  letter-spacing: 0;
}

.frame .img {
  position: relative;
  width: 328px;
  height: 280px;
  padding: 20px;
}

.frame .div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.frame .frame-wrapper {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 168px;
}

.frame .div-2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 13px;
  position: relative;
  top: 10px;
}

.frame .master-circle {
  position: relative;
  width: 72px;
  height: 72px;
}

.frame .p {
  font-family: "Pretendard-Bold", Helvetica;
  font-weight: 700;
  color: #15181e;
  font-size: 24px;
  line-height: 32px;
  position: relative;
  align-self: stretch;
  text-align: center;
  letter-spacing: 0;
}

</style>

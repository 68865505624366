<template>
  <div class="screen">
    <div class="content">
      <div class="top-bar-name">
        <div class="title">내 주문 확인</div>
      </div>
      <div class="frame">
        <div class="inpur-group">
          <Label bold class="label-instance" divClassName="text-wrapper-semibold" text="휴대폰번호" type="primary" />
          <OutlinedTextfield
              v-model="phoneNumber"
              class="outlined-textfield-instance"
              content="filled-text"
              size="large"
              state="default"
              userTextClassName="outlined-textfield-2"
              inputType="tel"
              placeholder-text="010"
          />
          <Label bold class="label-instance" divClassName="text-wrapper-semibold" text="주문번호" type="primary" />
          <OutlinedTextfield
              v-model="orderId"
              class="outlined-textfield-instance"
              content="filled-text"
              size="large"
              state="default"
              userTextClassName="outlined-textfield-2"
              inputType="number"
          />
        </div>
        <div class="primary-button-wrapper">
          <PrimaryButton
              autoLayoutButtonClassName="design-component-instance-node"
              className="primary-button-instance"
              :fullWidth="true"
              icon="none"
              labelClassName="primary-button-2"
              :rounded="true"
              size="large"
              state="default"
              text="확인"
              :tonal="true"
              @click="handleAuth"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import OutlinedTextfield from "@/components/OutlinedTextfield.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import Label from "@/components/Label.vue";
import { API_BASE_URL } from "@/config"; // 환경 변수

const phoneNumber = ref('');
const orderId = ref('');
const router = useRouter();

const handleAuth = async () => {
  if (phoneNumber.value && orderId.value) {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/orders/me/auth`, {
        phoneNumber: phoneNumber.value,
        orderId: orderId.value
      });

      if (response.data && response.data.jwt) {
        // orderId 별로 JWT를 localStorage에 저장
        localStorage.setItem(`jwt_order_${orderId.value}`, response.data.jwt);
        console.log('인증 성공:', response.data);
        await router.push({ name: 'OrderDetail', query: { orderId: orderId.value } });
      } else {
        alert('인증에 실패했습니다. 다시 시도해 주세요.');
      }
    } catch (error) {
      console.error('인증 오류:', error);
      alert('휴대폰번호와 주문번호를 정확히 입력해 주세요.');
    }
  } else {
    alert('휴대폰번호와 주문번호를 정확히 입력해 주세요.');
  }
};
</script>

<style scoped>
.screen {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  padding-top: 0;
}

.content {
  background-color: #ffffff;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.screen .top-bar-name {
  align-items: center;
  background-color: var(--x-semantic-lightsurface);
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  padding: 10px 16px;
  width: 100%;
}

.screen .frame {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 16px 10px;
  width: 100%;
  max-width: 600px;
}

.screen .title {
  color: var(--core-slate-800);
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.screen .inpur-group {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.primary-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.label-instance {
  color: #15181e !important;
  font-family: "Pretendard-SemiBold", Helvetica !important;
  font-size: 14px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
}

.outlined-textfield-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
  box-sizing: border-box !important;
}

.outlined-textfield-2 {
  font-family: "Pretendard-Regular", Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  width: 100% !important;
}
</style>
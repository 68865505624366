<template>
  <div class="frame">
    <div class="element">
      <div class="title">
        <div class="user-text">공구 셀러를 위한 스토어</div>
        <p class="text-wrapper">여러 공구를 한번에 관리하고,<br />실시간으로 판매 실적을 조회할 수 있어요</p>
      </div>
      <img class="img" src="/img/seller-img1.png" />
    </div>
    <div class="element">
      <div class="title">
        <div class="user-text">모든 결제 수단 지원</div>
        <p class="text-wrapper">네이버페이, 카카오페이, 신용카드, 현금결제<br />구매자에게 모두 제공할 수 있어요</p>
      </div>
      <img class="img" src="/img/seller-img2.png" />
    </div>
    <div class="element">
      <div class="div">
        <div class="user-text">투명한 수익 정산</div>
        <p class="text-wrapper">
          불투명한 공구 정산, 더 이상 스트레스 받지 마세요<br />판매내역을 투명하게 확인하고 정확하게 정산받아요
        </p>
      </div>
      <img class="img" src="/img/seller-img3.png" />
    </div>
    <div class="element">
      <div class="div">
        <div class="user-text">사업자 없이도 가능해요</div>
        <p class="text-wrapper">
          젤러에서는 누구나 공구를 시작할 수 있어요<br />개인도 부담없이 공구를 시작해 보세요
        </p>
      </div>
      <img class="img" src="/img/seller-img4.png" />
    </div>
    <div class="element">
      <div class="div">
        <div class="user-text">공구 제안이 찾아와요</div>
        <p class="text-wrapper">피곤한 DM에서 벗어나세요<br />젤러가 정리된 제안만 전달드려요</p>
      </div>
      <img class="img" src="/img/seller-img5.jpg" />
    </div>
    <div class="frame-wrapper">
      <div class="div-2">
        <img class="master-circle" src="/img/circle-warning.svg" />
        <p class="p">공구, 홍보만 신경쓰세요<br />나머지는 젤러가 해결할게요</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SellerFrameWrapper",
  components: {
  },
});
</script>

<style scoped>
.frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 48px;
  position: relative;
  padding-bottom: 100px;
}

.frame .element {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.frame .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.frame .user-text {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: "Pretendard-Bold", Helvetica;
  font-weight: 700;
  color: #15181e;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.frame .text-wrapper {
  font-family: "Pretendard-Medium", Helvetica;
  font-weight: 500;
  color: #4b515b;
  font-size: 15px;
  line-height: 26px;
  position: relative;
  align-self: stretch;
  text-align: center;
  letter-spacing: 0;
}

.frame .img {
  position: relative;
  width: 328px;
  height: 280px;
  padding: 20px;
}

.frame .div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.frame .frame-wrapper {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 168px;
}

.frame .div-2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 13px;
  position: relative;
  top: 10px;
}

.frame .master-circle {
  position: relative;
  width: 72px;
  height: 72px;
}

.frame .p {
  font-family: "Pretendard-Bold", Helvetica;
  font-weight: 700;
  color: #15181e;
  font-size: 24px;
  line-height: 32px;
  position: relative;
  align-self: stretch;
  text-align: center;
  letter-spacing: 0;
}

</style>

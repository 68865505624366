<template>
  <label
      :class="['assets-radio', `status-${status}`, className]"
  >
    <input
        type="radio"
        :name="name"
        :value="value"
        :checked="modelValue === value"
        @change="onChange"
        class="radio-input"
    />
    <span class="radio-display">
      <span v-if="modelValue === value" class="select" />
    </span>
  </label>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "AssetsRadio",
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    status: {
      type: String as PropType<"hover" | "default">,
      default: "default",
    },
    className: {
      type: String,
      default: "",
    },
  },
  emits: ['update:modelValue'],
  methods: {
    onChange() {
      this.$emit('update:modelValue', this.value);
    },
  },
});
</script>

<style scoped>
.assets-radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.assets-radio .radio-input {
  display: none;
}

.assets-radio .radio-display {
  border: 1px solid;
  border-radius: 100px;
  height: 16px;
  width: 16px;
  display: inline-block;
  position: relative;
}

.assets-radio .select {
  border-radius: 100px;
  height: 8px;
  position: absolute;
  top: 3px;
  left: 3px;
  width: 8px;
}

.assets-radio .radio-display {
  background-color: var(--colors-surface);
  border-color: var(--colors-border-minimal);
}

.assets-radio.status-default .radio-display {
  background-color: var(--colors-action-secondary);
}

.assets-radio.status-hover .radio-display {
  background-color: var(--colors-action-secondary-hover);
}

.assets-radio.status-default .select {
  background-color: var(--colors-action-primary);
}

.assets-radio.status-hover .select {
  background-color: var(--colors-action-primary-hover);
}
</style>
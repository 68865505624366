<template>
  <svg
    :class="['minus', className]"
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="path"
      d="M3.33337 8H12.6667"
      stroke="#15181E"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.6"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Minus",
  props: {
    className: {
      type: String,
      default: "",
    },
  },
});
</script>

<style scoped>
.minus {
  cursor: pointer;
}
</style>
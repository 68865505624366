import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['label', _ctx.className])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['text-wrapper', _ctx.divClassName])
    }, _toDisplayString(_ctx.text), 3)
  ], 2))
}
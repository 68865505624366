import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52445b3d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["name", "value", "checked"]
const _hoisted_2 = { class: "radio-display" }
const _hoisted_3 = {
  key: 0,
  class: "select"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(['assets-radio', `status-${_ctx.status}`, _ctx.className])
  }, [
    _createElementVNode("input", {
      type: "radio",
      name: _ctx.name,
      value: _ctx.value,
      checked: _ctx.modelValue === _ctx.value,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      class: "radio-input"
    }, null, 40, _hoisted_1),
    _createElementVNode("span", _hoisted_2, [
      (_ctx.modelValue === _ctx.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
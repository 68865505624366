import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a31a80a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product-profile" }
const _hoisted_2 = { class: "top-bar-name" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "div" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "div" }
const _hoisted_7 = { class: "carousel-wrapper" }
const _hoisted_8 = { class: "t-itle" }
const _hoisted_9 = { class: "text-wrapper" }
const _hoisted_10 = { class: "frame-3" }
const _hoisted_11 = { class: "title-2" }
const _hoisted_12 = { class: "frame-4" }
const _hoisted_13 = { class: "title-3" }
const _hoisted_14 = { class: "title-4" }
const _hoisted_15 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Truck = _resolveComponent("Truck")!
  const _component_CustomCarousel = _resolveComponent("CustomCarousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "frame",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToHome && _ctx.goToHome(...args)))
      }, [
        _createElementVNode("img", {
          class: "ellipse",
          alt: "Profile",
          src: _ctx.deal.seller.profileImage
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.deal.seller.displayName || 'Zeller market'), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          class: "img",
          alt: "Instagram",
          src: "/img/frame-131248.svg",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openInstagram && _ctx.openInstagram(...args)))
        }),
        _createVNode(_component_Truck, {
          alt: "Order",
          class: "truck-instance",
          onClick: _ctx.goToOrderAuth
        }, null, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_CustomCarousel, { deal: _ctx.deal }, null, 8, ["deal"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.deal.name), 1),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, "정가 " + _toDisplayString(_ctx.deal.displayOriginPrice.toLocaleString()) + "원", 1),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.calculateDiscountPercentage(_ctx.deal.displayOriginPrice, _ctx.deal.displayFinalPrice)) + "%", 1),
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.deal.displayFinalPrice.toLocaleString()) + "원", 1)
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: "view",
      innerHTML: _ctx.deal.detailContents
    }, null, 8, _hoisted_15)
  ]))
}
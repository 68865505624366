import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14f68336"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "textfield" }
const _hoisted_2 = ["value", "type", "placeholder", "maxlength", "disabled"]
const _hoisted_3 = {
  key: 0,
  class: "placeholder-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['outlined-textfield', _ctx.className])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.content === 'filled-text')
        ? (_openBlock(), _createElementBlock("input", {
            key: 0,
            class: _normalizeClass(['user-text', _ctx.userTextClassName, { disabled: _ctx.isDisabled }]),
            value: _ctx.modelValue,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
            type: _ctx.inputType,
            placeholder: _ctx.placeholderText || '',
            maxlength: _ctx.maxLength,
            disabled: _ctx.isDisabled
          }, null, 42, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.content === 'placeholder-text')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.placeholderText)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.placeholderText), 1))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import DealDetail from '../screens/DealDetail.vue';
import OrderAuth from '../screens/OrderAuth.vue';
import OrderDetail from '../screens/OrderDetail.vue';
import BrandLanding from '../screens/BrandLanding.vue';
import SellerLanding from "@/screens/SellerLanding.vue";
import SellerAuth from "@/screens/SellerAuth.vue";
import SellerOrderList from "@/screens/seller/SellerSalesStat.vue";
import SellerAskingForm from "@/screens/seller/SellerAskingForm.vue";
import BrandAskingForm from "@/screens/brand/BrandAskingForm.vue";
import Intro from "@/screens/etc/Intro.vue";
import Test from "@/components/CustomCarousel.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: (to) => {
            const currentDomain = window.location.hostname;

            // Debugging: Log the current domain
            console.log('Current domain:', currentDomain);

            // Check if the current domain is 'order.zeller.store'
            if (currentDomain === 'order.zeller.store') {
                console.log('Redirecting to /order-auth'); // Debugging: Log the redirection decision
                return '/order-auth'; // Redirect to '/order-auth' for 'order.zeller.store'
            } else if (currentDomain === 'ellen.zeller.store') {
                return '/10000018';
            } else if (currentDomain === 'he1da.zeller.store') {
                return '/10000019';
            } else if (currentDomain === 'yaeji.zeller.store') {
                return '/10000020';
            } else if (currentDomain === 'eune.zeller.store') {
                return '/20004247';
            } else if (currentDomain === 'eunbnim.zeller.store') {
                return '/20005248';
            } else if (currentDomain === 'hayon.zeller.store') {
                return '/20006138';
            }

            return '/intro';
        }
    },
    {
        path: '/RFVW010000017',
        redirect(to) {
            return '/10000018';
        },
    },
    {
        path: '/:dealId',
        component: DealDetail,
        props: route => ({ dealId: route.params.dealId })
    },
    {
        path: '/order-auth',
        name: 'OrderAuth',
        component: OrderAuth,
    },
    {
        path: '/order-detail',
        name: 'OrderDetail',
        component: OrderDetail,
    },
    {
        path: '/seller-auth',
        name: 'SellerAuth',
        component: SellerAuth,
    },
    {
        path: '/seller-sales-stat',
        name: 'SellerSalesStat',
        component: SellerOrderList,
    },
    {
        path: '/brand-landing',
        name: 'BrandLanding',
        component: BrandLanding,
    },
    {
        path: '/brand-asking-form',
        name: 'BrandAskingForm',
        component: BrandAskingForm,
    },
    {
        path: '/seller-landing',
        name: 'SellerLanding',
        component: SellerLanding,
    },
    {
        path: '/seller-asking-form',
        name: 'SellerAskingForm',
        component: SellerAskingForm,
    },
    {
        path: '/intro',
        name: 'Intro',
        component: Intro,
    },
    {
        path: '/test',
        name: 'Test',
        component: Test,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.afterEach((to) => {
    window.gtag('config', 'G-1G0C6FS9WR', {
        page_path: to.fullPath,
    });
});

export default router;
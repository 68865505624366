<template>
  <div class="sheet">
    <div class="div">
      <div class="label"><div class="text-wrapper">주문 상품</div></div>
      <div class="frame">
        <img class="img" :src="deal?.dealImages?.thumb[0]?.url" alt="상품 이미지" />
        <div class="frame-2">
          <p class="user-text">{{ deal.name }}</p>
          <div class="frame-3" v-for="option in selectedOptions" :key="option.optionId">
            <p class="user-text-3">옵션 {{ option.optionGroupName }}</p>
            <div class="user-text-quantity">{{ option.quantity }}개</div>
          </div>
        </div>
      </div>
    </div>
    <div class="div">
      <div class="frame-4">
        <div class="label"><div class="text-wrapper">총 결제액</div></div>
        <div class="div-wrapper">
          <div class="price-wrapper"><div class="price">{{ totalPaymentAmount.toLocaleString() }}원</div></div>
        </div>
      </div>
      <div class="frame-5">
        <div class="frame-6">
          <div class="label"><div class="label-2">상품금액</div></div>
          <div class="frame-wrapper">
            <div class="frame-7">
              <div class="title"></div>
              <div class="user-text-2">{{ totalPrice.toLocaleString() }}원</div>
            </div>
          </div>
        </div>
        <div class="frame-6">
          <div class="label"><div class="label-2">배송비</div></div>
          <div class="div-wrapper">
            <div class="user-text-2">
              {{ deliveryFeeText }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { DealOption } from "@/types/DealOption";

export default defineComponent({
  name: "OrderFormPaymentInfo",
  props: {
    deal: {
      type: Object,
      required: true,
    },
    selectedOptions: {
      type: Array as () => DealOption[],
      required: true,
    }
  },
  setup(props) {
    // 총 결제 금액 계산
    const totalPrice = computed(() => {
      return props.selectedOptions.reduce(
          (acc, option) => acc + option.displayFinalPrice * option.quantity,
          0
      );
    });

    // 할인가 계산
    const totalDiscount = computed(() => {
      return props.selectedOptions.reduce(
          (acc, option) => acc + (option.displayOriginalPrice - option.displayFinalPrice) * option.quantity,
          0
      );
    });

    // 할인율 계산
    const discountRate = computed(() => {
      const originalPrice = totalPrice.value + totalDiscount.value;
      return Math.round((totalDiscount.value / originalPrice) * 100);
    });

    // 배송비 텍스트 계산
    const deliveryFeeText = computed(() => {
      if (props.deal.deliveryFee === null || props.deal.deliveryFee === 0 || props.deal.deliveryFee === undefined) {
        return "무료";
      } else {
        return `${props.deal.deliveryFee?.toLocaleString()}원`;
      }
    });

    // 배송비 계산
    const deliveryFee = computed(() => {
      return props.deal.deliveryFee || 0;
    });

    // 총 결제 금액 계산 (상품 금액 + 배송비)
    const totalPaymentAmount = computed(() => {
      return totalPrice.value + deliveryFee.value;
    });

    return {
      totalPrice,
      discountRate,
      totalDiscount,
      deliveryFeeText,
      totalPaymentAmount,
    };
  }
});
</script>



<style scoped>
.sheet {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: var(--size-space-200) var(--size-space-400) var(--size-space-0) var(--size-space-400);
  position: relative;
  width: 100%;
}

.sheet .div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: var(--size-space-400);
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #f8f9fb;
  border-radius: var(--shape-corner-small);
}

.sheet .label {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-space-1);
  position: relative;
  flex: 0 0 auto;
}

.sheet .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: #717680;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.sheet .frame {
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.sheet .img {
  position: relative;
  width: 60px;
  height: 60px;
}

.sheet .frame-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.sheet .user-text {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: "Pretendard-Bold", Helvetica;
  font-weight: 700;
  color: #15181e;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.sheet .frame-3 {
  display: flex;
  align-items: flex-start;
  gap: 6px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.sheet .user-text-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: 400;
  color: #717680;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.sheet .user-text-3 {
  position: relative;
  margin-top: -1px;
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: 400;
  color: #717680;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  word-wrap: break-word; /* 단어가 넘치면 줄바꿈 */
  word-break: break-all;  /* 긴 단어도 줄바꿈 */
  white-space: normal; /* 여러 줄에 걸쳐 텍스트 표시 */
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* 텍스트가 부모 컨테이너를 넘지 않도록 제한 */
  flex-grow: 1;
}

.sheet .user-text-quantity {
  position: relative;
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: 400;
  color: #717680;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  text-align: right;
  flex-shrink: 0; /* 수량 부분은 절삭되지 않도록 고정 */
}

.sheet .frame-4 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  flex: 0 0 auto;
  width: 100%;
}

.sheet .div-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: var(--size-space-100);
  position: relative;
  flex: 0 0 auto;
}

.sheet .price-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
}

.sheet .price {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-Bold", Helvetica;
  font-weight: 700;
  color: #15181e;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.sheet .frame-5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.sheet .frame-6 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.sheet .label-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: #717680;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.sheet .frame-wrapper {
  display: flex;
  width: 164.5px;
  align-items: center;
  justify-content: flex-end;
  gap: 83px;
  position: relative;
}

.sheet .frame-7 {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  position: relative;
  flex: 0 0 auto;
}

.sheet .title {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: 400;
  color: #ff3a3a;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

</style>
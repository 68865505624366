<template>
  <div v-if="isSheetVisible">
    <custom-bottom-sheet ref="myBottomSheet"
                         :overlay="true"
                         :overlay-click-close="true"
                         :transition-duration="0.2"
                         :max-width="600"
                         @closed="switchToBasic">
      <div class="bottom">
        <div class="sheet">
          <div class="con">
            <div class="title">
              <div class="text-wrapper">결제를 완료했어요</div>
              <p class="user-text">카카오 알림톡에서 주문정보를 확인해 보세요.</p>
            </div>
            <img class="master-circle" alt="Master circle" src="/img/master-circle-warning.svg" />
            <div class="frame">
              <div class="group">
                <div class="overlap-group">
                  <div class="drop-shadow" />
                  <div class="alarm-talk">
                    <div class="div">
                      <div class="icon">
                        <img class="img" alt="Rectangle" src="/img/rectangle-6.png" />
                        <div class="title-2">카카오톡</div>
                      </div>
                      <div class="title-3">지금</div>
                    </div>
                    <div class="frame-2">
                      <div class="text-wrapper-2">알림톡 도착</div>
                      <p class="p">고객님의 주문이 접수되었습니다. 주문번호를 확인하세요</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="CTA">
            <div class="primary-button-wrapper">
              <PrimaryButton
                  autoLayoutButtonClassName="design-component-instance-node"
                  className="primary-button-instance"
                  :fullWidth="true"
                  icon="none"
                  labelClassName="primary-button-2"
                  :rounded="true"
                  size="large"
                  state="default"
                  text="확인"
                  :tonal="true"
                  @click="switchToBasic"
              />
            </div>
          </div>
          <div class="help">
            <div class="help_frame">
              <div class="help_user-text">알림톡이 오지 않았나요?</div>
              <div class="help_text-wrapper" @click="sendEmail(orderId)">문의하기</div>
            </div>
          </div>
        </div>
      </div>
    </custom-bottom-sheet>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, nextTick } from "vue"; // nextTick 추가
import { useRouter } from "vue-router";
import PrimaryButton from "@/components/PrimaryButton.vue";
import CustomBottomSheet from "@/components/CustomBottomSheet.vue";
import "@webzlodimir/vue-bottom-sheet/dist/style.css";

export default defineComponent({
  name: "BottomSuccess",
  components: {
    CustomBottomSheet,
    PrimaryButton,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  emits: ["switch-to-basic"],  // BasicBottom 으로 전환을 부모에게 알림
  setup(_, { emit }) {
    const router = useRouter();
    const myBottomSheet = ref<InstanceType<typeof CustomBottomSheet> | null>(null);
    const isSheetVisible = ref(false);

    onMounted(() => {
      isSheetVisible.value = true;

      nextTick(() => {
        // BottomSheet open method called after the sheet is rendered
        if (myBottomSheet.value) {
          myBottomSheet.value.open();
        } else {
          console.error("VueBottomSheet is not available.");
        }
      });
    });

    const switchToBasic = () => {
      if (myBottomSheet.value) {
        myBottomSheet.value.close();
      }
      emit("switch-to-basic");
      const baseUrl = window.location.pathname;
      router.push(baseUrl); // 화면 리프레쉬 시 BottomSuccess 가 중복해서 보이지 않도록
    };

    return {
      switchToBasic,
      myBottomSheet,
      isSheetVisible,
    };
  },
  methods: {
    sendEmail(orderId: string) {
      const email = "admin@zeller.store";
      const subject = encodeURIComponent("주문 문의");
      const body = encodeURIComponent("주문 번호 : " + orderId + "\n\n문의 내용 : ");
      window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    },
  },
});
</script>

<style scoped>
.bottom {
  display: flex;
  flex-direction: column;
  height: auto;
}

.sheet {
  overflow: visible;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottom .handel {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--size-space-200);
  padding: var(--size-space-300) var(--size-space-400) var(--size-space-0) var(--size-space-400);
  position: relative;
  width: 100%;
}

.bottom .rectangle {
  background-color: var(--core-slate-100);
  border-radius: var(--shape-corner-extra-small);
  height: 4px;
  position: relative;
  width: 40px;
}

.bottom .con {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding: 24px 16px 16px;
  position: relative;
  width: 100%;
}

.bottom .title {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: 100%;
}

.bottom .text-wrapper {
  align-self: stretch;
  color: #1d2027;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.bottom .user-text {
  align-self: stretch;
  color: #717680;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  text-align: center;
}

.bottom .master-circle {
  height: 72px;
  position: relative;
  width: 72px;
}

.bottom .frame {
  height: 108px;
  position: relative;
  width: 100%;
}

.bottom .group {
  height: 98px;
}

.bottom .overlap-group {
  border-radius: 12px;
  height: 98px;
  position: relative;
  width: 100%;
}

.bottom .drop-shadow {
  background-color: #ffffff;
  box-shadow: 0px 4px 24px #0000001f, 0px 2px 67px #0000000d;
  height: 86px;
  left: 24px;
  position: absolute;
  top: 12px;
  width: 90%;
}

.bottom .alarm-talk {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(242.4, 248.42, 225.27) 50%, rgb(255, 255, 255) 100%);
  border: 1px solid;
  border-color: #d9d9d980;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  left: 0;
  padding: 12px;
  position: absolute;
  top: 0;
  width: 100%;
}

.bottom .div {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.bottom .icon {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.bottom .img {
  height: 24px;
  object-fit: cover;
  position: relative;
  width: 24px;
}

.bottom .title-2 {
  color: #717680;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.bottom .title-3 {
  color: #717680;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.bottom .frame-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2px;
  position: relative;
  width: 100%;
}

.bottom .text-wrapper-2 {
  align-self: stretch;
  color: #15181e;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
}

.bottom .p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: #15181e;
  display: -webkit-box;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.bottom .CTA {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: var(--shape-corner-large) var(--shape-corner-large) 0px 0px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--size-space-0);
  position: relative;
  width: 100%;
}

.bottom .primary-button-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: var(--size-space-400);
  position: relative;
  width: 100%;
}

.help {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: center;
  padding: 6px 0px;
  position: relative;
  width: 100%;
}

.help .help_frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}

.help .help_user-text {
  color: #717680;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.help .help_text-wrapper {
  color: #1d2027;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}
</style>
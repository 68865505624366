<template>
  <div class="shipments-frame-4">
    <div class="outlined-textfield-3" @click="openSheet">
      <Label bold class="label-instance" divClassName="text-wrapper-semibold" text="배송지" type="primary" />
      <div class="textfield-2">
        <PrefixLarge class="prefix-large-instance" state="default" type="icon" />
        <div class="placeholder-label-2">주소검색</div>
      </div>
    </div>
    <OutlinedTextfield
        class="outlined-textfield-instance"
        content="filled-text"
        v-model="shipmentAddress"
        :max-length="250"
        size="large"
        state="default"
        userTextClassName="outlined-textfield-2"
        :disabled="true"
    />
    <!-- BottomSheet 사용 -->
    <custom-bottom-sheet v-if="isSheetVisible" ref="addressSheet"
                         :title="`주소`"
                         :can-swipe="false"
                         :overlay="true"
                         :overlay-click-close="true"
                         :transition-duration="0.2"
                         :max-width="600"
                         @closed="closeSheet">
      <div class="frame">
        <OutlinedTextfield
            class="outlined-textfield-instance"
            content="filled-text"
            placeholder-text="지번, 도로명, 건물명 검색"
            v-model="query"
            :max-length="200"
            size="large"
            state="default"
            userTextClassName="outlined-textfield-2"
            @input="searchAddress"
        />
        <!-- 검색 결과 표시 -->
        <div class="section-list" v-if="keywordResults.length">
          <div
              v-for="(place, index) in keywordResults.filter(place => place.road_address_name)"
              :key="index"
              class="div"
              @click="selectPlaceFromKeyword(place)"
          >
            <div class="div">
              <div class="div-2">
                <div class="label"><div class="text-wrapper">도로명</div></div>
                <div class="label-2">
                  <p class="p">
                    {{ place.road_address_name }}
                    <span v-if="place.place_name"> ({{ place.place_name }})</span>
                  </p>
                </div>
              </div>
              <div class="div-2">
                <div class="label"><div class="text-wrapper">지번</div></div>
                <div class="label-2">
                  <div class="user-text-2">{{ place.address_name || '정보 없음' }}</div>
                </div>
              </div>
            </div>
            <div class="rectangle"></div>
          </div>
        </div>

        <div class="section-init" v-else-if="!keywordResults.length">
          <div class="user-text">정확한 주소를 입력해 주세요</div>
          <div class="div">
            <div class="div-2">
              <div class="label"><div class="text-wrapper">지역명(동/리)+번지</div></div>
              <div class="user-text-2">예) 서초동 12-3</div>
            </div>
            <div class="div-2">
              <div class="label"><div class="text-wrapper">도로명+건물번호</div></div>
              <div class="user-text-2">예) 테헤란로 13</div>
            </div>
            <div class="div-3">
              <div class="text-wrapper-2">지역명 (동/리)+건물명(아파트명)</div>
              <div class="label-2"><div class="label-3">서초동 에이빌딩</div></div>
            </div>
          </div>
        </div>
      </div>
    </custom-bottom-sheet>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, nextTick } from "vue";
import axios from 'axios';
import CustomBottomSheet from "@/components/CustomBottomSheet.vue";
import Label from "@/components/Label.vue";
import OutlinedTextfield from "@/components/OutlinedTextfield.vue";
import PrefixLarge from "@/components/PrefixLarge.vue";
import { API_BASE_URL } from "@/config";

export default defineComponent({
  name: "KakaoAddressSearch",
  components: { PrefixLarge, CustomBottomSheet, OutlinedTextfield, Label },
  props: {
    shipmentAddress: {
      type: String,
      default: "",
    },
    postalCode: {
      type: String,
      default: "",
    }
  },
  emits: ["update:shipmentAddress", "update:postalCode"],
  setup(props, { emit }) {
    const shipmentAddress = ref(props.shipmentAddress);
    const query = ref("");
    const keywordResults = ref<any[]>([]);
    const isSheetVisible = ref(false);
    const addressSheet = ref<InstanceType<typeof CustomBottomSheet> | null>(null);

    const openSheet = () => {
      isSheetVisible.value = true;
      nextTick(() => {
        if (addressSheet.value) {
          addressSheet.value.open();
        }
      });
    };

    const closeSheet = () => {
      isSheetVisible.value = false;
    };

    const searchAddress = () => {
      if (!query.value) {
        keywordResults.value = [];
        return;
      }

      const ps = new window.kakao.maps.services.Places();
      ps.keywordSearch(query.value, async (result: any, status: any) => {
        if (status === window.kakao.maps.services.Status.OK) {
          keywordResults.value = result;
        } else {
          keywordResults.value = [];
        }
      });
    };

    const selectPlaceFromKeyword = async (place: any) => {
      const fullAddress = `${place.road_address_name || '정보 없음'}${place.place_name ? ` (${place.place_name})` : ''}`;
      shipmentAddress.value = fullAddress;

      // 부모 컴포넌트로 먼저 주소 전달
      emit('update:shipmentAddress', fullAddress);

      closeSheet(); // 먼저 Sheet를 닫습니다.

      // 비동기적으로 postalCode API 호출
      try {
        const response = await axios.post(`${API_BASE_URL}/api/address/postal-codes`, {
          roadAddress: place.road_address_name
        }, { timeout: 5000 });

        const postalCodes = response.data;
        if (postalCodes.length > 0) {
          emit('update:postalCode', postalCodes[0]);  // 첫 번째 우편번호를 설정
        } else {
          emit('update:postalCode', '');
        }
      } catch (error) {
        console.error("Failed to fetch postal code or request timed out:", error);
        emit('update:postalCode', ''); // 타임아웃이나 실패 시 빈 우편번호 설정
      }

      keywordResults.value = [];
    };

    return {
      query,
      keywordResults,
      isSheetVisible,
      openSheet,
      closeSheet,
      searchAddress,
      selectPlaceFromKeyword,
      addressSheet,
      shipmentAddress,
    };
  },
});
</script>




<style scoped>
.shipments-frame-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}
.outlined-textfield-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-space-15);
  min-width: 80px;
  position: relative;
  width: 100%;
}
.outlined-textfield-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  min-width: 80px !important;
  width: 100% !important;
}

.label-instance {
  flex: 0 0 auto !important;
}

.textfield-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--colors-field);
  border: 1px solid;
  border-color: var(--colors-border);
  border-radius: var(--borders-rounded-medium);
  display: flex;
  height: 48px;
  min-width: 80px;
  overflow: hidden;
  padding: var(--spacing-space-3) var(--spacing-space-4) var(--spacing-space-3) var(--spacing-space-4);
  position: relative;
  width: 100%;
}

.prefix-large-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
}

.placeholder-label-2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--colors-text-sub);
  display: -webkit-box;
  flex: 1;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}
.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 16px;
  position: relative;
  height: 80vh; /* 고정 높이 */
}

.frame .search-sm {
  position: relative;
  width: 16px;
  height: 16px;
}

.frame .section-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: var(--size-space-400);
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
  background-color: #f8f9fb;
  border-radius: var(--shape-corner-small);
  overflow: auto;
}

.section-list .div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.section-list .div-2 {
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.section-list .label {
  display: flex;
  width: 37px;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-space-1);
  position: relative;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  border-color: #d9d9d9;
}

.section-list .text-wrapper {
  position: relative;
  width: fit-content;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: #717680;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 14px;
  white-space: nowrap;
}

.section-list .label-2 {
  flex: 1;
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: var(--spacing-space-1);
  position: relative;
}

.section-list .p {
  position: relative;
  flex: 1;
  margin-top: -1px;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: var(--colors-text);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.section-list .user-text-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: 400;
  color: var(--colors-text);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.section-list .rectangle {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin-top: 10px;
}

.section-list .label-3 {
  width: 37px;
  justify-content: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: var(--spacing-space-1);
  position: relative;
}

.section-list .div-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  margin-bottom: -16px;
}

.frame .section-init {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: var(--size-space-400);
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #f8f9fb;
  border-radius: var(--shape-corner-small);
  display: flex;
  position: relative;
}

.section-init .user-text {
  position: relative;
  width: 296px;
  height: 24px;
  margin-top: -1px;
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: 400;
  color: var(--colors-text);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.section-init .div {
  width: 280px;
  justify-content: center;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}

.section-init .div-2 {
  width: 120px;
  gap: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}

.section-init .label {
  align-items: center;
  gap: var(--spacing-space-1);
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  position: relative;
}

.section-init .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: #717680;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.section-init .user-text-2 {
  position: relative;
  align-self: stretch;
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: 400;
  color: #717680;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.section-init .div-3 {
  width: 191px;
  gap: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
}

.section-init .text-wrapper-2 {
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: #717680;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}

.section-init .label-2 {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-space-1);
  position: relative;
  flex: 0 0 auto;
}

.section-init .label-3 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: 400;
  color: #717680;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}
</style>

<template>
  <div class="sheet-option-wrapper">
    <Label
        bold
        class="label-5"
        divClassName="label-6"
        text="결제 수단"
        type="primary"
    />
    <div class="tab">
      <div class="variant-filled-size-wrapper">
        <ActionTag
            v-for="tag in actionTags"
            :key="tag.value"
            :label="tag.label"
            :value="tag.value"
            :state="localValue === tag.value ? 'selected' : 'default'"
            @click="updateSelectedValue(tag.value)"
        />
      </div>
    </div>
    <div class="radio-button-group" v-if="localValue === 'simplePay'">
      <div class="frame-5">
        <div class="radio" @click="localRadioValue = 'naverpayCard'">
          <AssetsRadio
              v-model="localRadioValue"
              class="assets-radio-instance"
              name="paymentMethod"
              value="naverpayCard"
              size="medium"
              status="default"
          />
          <div class="LOGO-NAVER-pay">
            <img class="images" alt="Images" src="/img/images-1.png" />
          </div>
        </div>
        <div class="label-7">네이버페이</div>
      </div>
      <div class="shipments-frame-5">
        <div class="radio" @click="localRadioValue = 'kakaopay'">
          <AssetsRadio
              v-model="localRadioValue"
              class="assets-radio-instance"
              name="paymentMethod"
              value="kakaopay"
              size="medium"
              status="default"
          />
          <div class="auto-layout-label-2">
            <img class="logo-wechatpay" alt="Logo wechatpay" src="/img/logo-wechatpay.svg" />
          </div>
        </div>
        <div class="label-7">카카오페이</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import ActionTag from "@/components/ActionTag.vue";
import Label from "@/components/Label.vue";
import AssetsRadio from "@/components/AssetsRadio.vue";

export default defineComponent({
  name: "OrderFormPaymentMethod",
  components: {
    ActionTag,
    Label,
    AssetsRadio,
  },
  props: {
    modelValue: {
      type: String,
      default: "simplePay",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const actionTags = ref([
      { label: "간편결제", value: "simplePay" },
      { label: "카드", value: "card" },
      //{ label: "현금", value: "bank" },
      //{ label: "휴대폰", value: "cellphone" },
    ]);

    const localValue = ref(props.modelValue);
    const localRadioValue = ref("naverpayCard");

    const updateSelectedValue = (value: string) => {
      localValue.value = value;
      if (value === "simplePay") {
        localRadioValue.value = "naverpayCard";
        emit("update:modelValue", localRadioValue.value);
      } else {
        emit("update:modelValue", value);
      }
    };

    watch(localRadioValue, (newValue) => {
      if (localValue.value === "simplePay") {
        emit("update:modelValue", newValue);
      }
    });

    onMounted(() => {
      // 초기화 시 간편결제 및 네이버페이 선택
      updateSelectedValue("simplePay");
    });

    return {
      localValue,
      localRadioValue,
      actionTags,
      updateSelectedValue,
    };
  },
});
</script>





<style scoped>
.sheet-option-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--size-space-300);
  padding: var(--size-space-400) var(--size-space-400) 16px var(--size-space-400);
  position: relative;
  width: 100%;
}

.sheet-option-wrapper .label-5 {
  flex: 0 0 auto !important;
}

.sheet-option-wrapper .label-6 {
  color: #15181e !important;
  font-family: "Pretendard-SemiBold", Helvetica !important;
  font-size: 14px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
}

.variant-filled-size-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-wrap: wrap;
  gap: var(--spacing-space-1);
  position: relative;
}

.variant-filled-size-wrapper .action-tag-instance {
  flex: 0 0 auto !important;
}

.sheet-option-wrapper .radio-button-group {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column; /* 수정된 부분 */
  gap: var(--size-space-200);
  position: relative;
  width: 100%;
}

.sheet-option-wrapper .frame-5 {
  align-items: center; /* 수정된 부분 */
  display: flex;
  justify-content: space-between; /* 수정된 부분 */
  width: 100%; /* 수정된 부분 */
}

.sheet-option-wrapper .shipments-frame-5 {
  align-items: center; /* 수정된 부분 */
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between; /* 수정된 부분 */
  padding: 6px 0px;
  position: relative;
  width: 100%; /* 수정된 부분 */
}

.sheet-option-wrapper .radio {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  padding: var(--spacing-space-05) var(--spacing-space-0) var(--spacing-space-05) var(--spacing-space-0);
  position: relative;
}

.sheet-option-wrapper .assets-radio-instance {
  position: relative !important;
}

.sheet-option-wrapper .LOGO-NAVER-pay {
  height: 32px;
  position: relative;
  width: 81px;
}

.sheet-option-wrapper .images {
  height: 24px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 70px;
}

.sheet-option-wrapper .label-7 {
  color: #717680;
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.sheet-option-wrapper .auto-layout-label-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.sheet-option-wrapper .logo-wechatpay {
  height: 32px;
  position: relative;
  width: 101px;
}
</style>
<template>
  <div class="section-component-node">
    <img class="frame-9" alt="Frame" src="/img/frame-131260.svg" />
    <div class="content">
      <div class="text-content">
        <div class="user-text-25">판매량으로 말하는 인스타 공구</div>
        <p class="user-text-26">
          <span class="span">젤러</span>
          <span class="text-wrapper-2">와 함께 하세요</span>
        </p>
      </div>
      <div class="profile">
        <img src="/img/profile.png" alt="Profile" class="profile-image" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BrandProfileSection",
});
</script>

<style scoped>
.section-component-node {
  background: linear-gradient(180deg, rgb(21, 24, 30) 50%, rgba(21, 24, 30, 0) 100%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-component-node .frame-9 {
  height: 58px;
  width: 100%;
}

.section-component-node .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px;
}

.section-component-node .text-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  width: 100%;
}

.section-component-node .user-text-25 {
  color: #ffffff;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 29.6px;
  text-align: center;
  width: fit-content;
}

.section-component-node .user-text-26 {
  color: transparent;
  font-family: "Pretendard-Bold", Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 38.5px;
  text-align: center;
  width: fit-content;
}

.section-component-node .span {
  color: #d2ff53;
  font-weight: 700;
}

.section-component-node .text-wrapper-2 {
  color: #ffffff;
  font-family: "Pretendard-Medium", Helvetica;
  font-weight: 500;
}

.section-component-node .profile {
  display: flex;
  justify-content: center;
  width: 96%;
  max-width: 600px;
  margin-top: 20px;
}

.section-component-node .profile-image {
  width: 100%;
  height: auto;
  border-radius: 18px;
}
</style>

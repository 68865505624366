<template>
  <svg
    :class="['chevron-down-1', className]"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="path"
      d="M6 9L12 15L18 9"
      stroke="#15181E"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.2"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "ChevronDown1",
  props: {
    className: {
      type: String,
      default: "",
    },
  },
});
</script>

<style scoped>
.chevron-down {
  position: relative;
  width: 16px;
  height: 16px;
}
</style>
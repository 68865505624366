<template>
  <button class="button" @click="sendEmail">
    <div class="outlined-button">
      <div class="auto-layout-button">
        <div class="label-5">문제가 있으신가요?</div>
      </div>
    </div>
  </button>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {Order} from "@/types/Order";

export default defineComponent({
  name: "OrderViewButton",
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  methods: {
    sendEmail() {
      const email = "admin@zeller.store";
      const subject = encodeURIComponent("주문 문의");
      const body = encodeURIComponent("주문 번호 : " + this.order.orderId + "\n\n문의 내용 : ");
      window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
    },
  },
});
</script>

<style scoped>
.button {
  all: unset;
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 16px 16px 48px;
  position: relative;
  width: 100%;
}

.button .outlined-button {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.button .auto-layout-button {
  align-items: center;
  background-color: var(--colors-action-secondary);
  border: 1px solid;
  border-color: var(--colors-border);
  border-radius: 8px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: var(--spacing-space-2);
  justify-content: center;
  min-width: var(--properties-button-medium-minwidth);
  padding: var(--spacing-space-2) var(--spacing-space-3) var(--spacing-space-2) var(--spacing-space-3);
  position: relative;
}

.button .label-5 {
  color: var(--colors-text-secondary);
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
</style>

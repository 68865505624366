import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a1a4e28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bottom" }
const _hoisted_2 = { class: "CTA" }
const _hoisted_3 = { class: "primary-button-wrapper" }
const _hoisted_4 = { class: "banner-countdwon" }
const _hoisted_5 = { class: "title-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PrimaryButton, {
          autoLayoutButtonClassName: "design-component-instance-node",
          className: "primary-button-instance",
          fullWidth: true,
          icon: "none",
          labelClassName: "primary-button-2",
          rounded: true,
          size: "large",
          state: "default",
          text: "주문하기",
          tonal: true,
          onClick: _ctx.switchToOptions
        }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.countdownText), 1)
      ])
    ])
  ]))
}
<template>
  <div class="screen">
    <div class="div">
      <div class="frame-wrapper">
        <div class="profile-wrapper">
          <div class="profile">
            <div class="title-3">내 판매실적</div>
            <div class="title-4">{{ sellerName }}</div>
          </div>
        </div>
      </div>
      <div class="list">
        <!-- 업데이트된 section -->
        <div class="section" v-if="dealStat">
          <div class="overlap-group">
            <div class="SD"></div>
            <div class="section-2">
              <div class="labe-button">
                <div class="frame">
                  <div class="title">진행중</div>
                </div>
                <div class="frame-2">
                  <div class="user-text">{{ formattedStartDate }}</div>
                  <div class="user-text">-</div>
                  <div class="user-text">{{ formattedEndDate }}</div>
                </div>
              </div>
              <div class="div-2">
                <div class="frame-3">
                  <div class="text-wrapper">{{ dealStat.deal.name }}</div>
                  <div class="primary-button" @click="openSellerLink">
                    <div class="auto-layout-button">
                      <div class="label">Link</div>
                      <img class="img" src="/img/icon-2.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div class="frame-4">
                  <div class="div-3">
                    <div class="label-2">
                      <div class="label-3">판매 합계</div>
                    </div>
                    <div class="user-text">{{ formatCurrency(dealStat.totalSalesAmount) }} 원</div>
                  </div>
                  <div class="div-3">
                    <div class="label-2">
                      <div class="label-3">구매인원</div>
                    </div>
                    <div class="user-text">{{ dealStat.totalOrders }} 명</div>
                  </div>
                </div>
              </div>
              <div class="rectangle"></div>
              <div class="div-3">
                <div class="label-2">
                  <div class="label-3">내 예상 수익</div>
                </div>
                <div class="user-text-2">{{ formatCurrency(dealStat.profitAmount) }} 원</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 페이지네이션 -->
        <div class="pagenation">
          <div class="selected"><div class="user-text-4">1</div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios from "axios";
import { API_BASE_URL } from "@/config"; // 환경 변수

export default defineComponent({
  name: "SellerSalesStat",
  components: {},
  setup() {
    const dealStat = ref<any>(null);
    const route = useRoute();
    const router = useRouter();
    const sellerName = ref<string | null>(null);
    const sellerSubDomain = ref<string | null>(null);

    onMounted(async () => {
      const sellerId = route.query.sellerId as string;
      const jwtToken = localStorage.getItem(`jwt_seller_${sellerId}`);

      if (jwtToken) {
        try {
          const response = await axios.get(`${API_BASE_URL}/api/sellers/deal-stats`, {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          });
          dealStat.value = response.data.dealStats[0]; // 첫 번째 dealStat를 설정
          sellerName.value = dealStat.value.deal.seller?.name || 'Unknown Seller'; // seller 이름 설정
          sellerSubDomain.value = response.data.dealStats[0].deal.seller?.subDomainName || ''; // 서브도메인 설정
          console.log('판매 실적 정보:', dealStat.value);
        } catch (err) {
          const error = err as any;
          if (error.response && error.response.status === 401) {
            alert('인증 정보가 만료되었습니다. 다시 인증정보를 입력하여 주세요.');
            await router.push({ name: 'SellerAuth' });
          } else {
            console.error('오류 발생:', error);
            alert('판매 정보를 가져오는 중 오류가 발생했습니다.');
          }
        }
      } else {
        alert('인증 정보가 만료되었습니다. 다시 인증정보를 입력하여 주세요.');
        await router.push({ name: 'SellerAuth' });
      }
    });

    const openSellerLink = () => {
      const url = `https://${sellerSubDomain.value}.zeller.store`;
      window.open(url, '_blank');
    };

    const formatCurrency = (value: number): string => {
      return value.toLocaleString('ko-KR');
    };

    const formatDate = (timestamp: number): string => {
      const date = new Date(timestamp * 1000); // 초 단위를 밀리초로 변환
      return date.toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    };

    const formattedStartDate = computed(() =>
        dealStat.value ? formatDate(dealStat.value.deal.startDate) : ''
    );

    const formattedEndDate = computed(() =>
        dealStat.value ? formatDate(dealStat.value.deal.endDate) : ''
    );

    return {
      dealStat,
      sellerName,
      formattedStartDate,
      formattedEndDate,
      formatCurrency,
      openSellerLink, // 링크 클릭 핸들러
    };
  },
});
</script>

<style scoped>
.screen {
  background-color: #f8f9fb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.screen .div {
  background-color: #f8f9fb;
  width: 100%;
  position: relative;
  max-width: 600px;
}

.screen .list {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  padding: 10px;
}

.screen .section {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 197px;
}

.screen .overlap-group {
  position: relative;
  width: 100%;
  height: 197px;
  border-radius: 12px;
}

.screen .SD {
  position: absolute;
  width: 86%;
  height: 119px;
  top: 78px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d9d9d9;
  box-shadow: 0px 4px 24px #0000001a, 0px 0px 24px #00000005;
}

.screen .section-2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
  padding: var(--size-space-400);
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  border-radius: 12px;
  border: 1px solid;
  border-color: #d9d9d9;
}

.screen .labe-button {
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.screen .frame {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 4px 8px;
  position: relative;
  flex: 0 0 auto;
  background-color: #d2ff53;
  border-radius: 50px;
}

.screen .title {
  margin-top: -1px;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: var(--core-slate-800);
  font-size: 12px;
  line-height: 20px;
  position: relative;
  width: fit-content;
  letter-spacing: 0;
  white-space: nowrap;
}

.screen .frame-2 {
  display: inline-flex;
  align-items: flex-start;
  gap: 2px;
  position: relative;
  flex: 0 0 auto;
}

.screen .user-text {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: 400;
  color: #717680;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.screen .div-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 0px 0px 4px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.screen .frame-3 {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.screen .text-wrapper {
  flex: 1;
  font-family: "Pretendard-Bold", Helvetica;
  font-weight: 700;
  color: #15181e;
  font-size: 18px;
  line-height: 28px;
  -webkit-line-clamp: 1;
  position: relative;
  margin-top: -1px;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.screen .primary-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: stretch;
  flex: 0 0 auto;
  cursor: pointer;
}

.screen .auto-layout-button {
  display: inline-flex;
  min-width: 40px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: var(--spacing-space-15) var(--spacing-space-2) var(--spacing-space-15) var(--spacing-space-2);
  position: relative;
  flex: 0 0 auto;
  background-color: var(--colors-action-primary);
  border-radius: var(--borders-rounded-small-duplicate);
}

.screen .label {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-weight: var(--label-small-bold-font-weight);
  color: var(--colors-text-inverse);
  font-size: var(--label-small-bold-font-size);
  letter-spacing: var(--label-small-bold-letter-spacing);
  line-height: var(--label-small-bold-line-height);
  white-space: nowrap;
  font-style: var(--label-small-bold-font-style);
}

.screen .img {
  position: relative;
  width: 16px;
  height: 16px;
}

.screen .frame-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  display: flex;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.screen .div-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.screen .label-2 {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-space-1-duplicate);
  position: relative;
  flex: 0 0 auto;
}

.screen .label-3 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-weight: 600;
  color: var(--colors-text-sub);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.screen .rectangle {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 1px;
  background-color: #d9d9d980;
}

.screen .user-text-2 {
  width: fit-content;
  font-family: "Pretendard-Bold", Helvetica;
  font-weight: 700;
  color: #15181e;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  -webkit-line-clamp: 2;
  position: relative;
  margin-top: -1px;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.screen .section-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding: var(--size-space-400);
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff;
  border-radius: 12px;
}

.screen .title-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 4px 8px;
  position: relative;
  flex: 0 0 auto;
  background-color: #d9d9d9;
  border-radius: 50px;
}

.screen .title-2 {
  margin-top: -1px;
  opacity: 0.3;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: var(--core-slate-800);
  font-size: 12px;
  line-height: 20px;
  position: relative;
  width: fit-content;
  letter-spacing: 0;
  white-space: nowrap;
}

.screen .frame-5 {
  display: inline-flex;
  align-items: flex-start;
  gap: 2px;
  position: relative;
  flex: 0 0 auto;
  opacity: 0.3;
}

.screen .user-text-3 {
  align-self: stretch;
  opacity: 0.3;
  font-family: "Pretendard-Bold", Helvetica;
  font-weight: 700;
  color: #15181e;
  font-size: 18px;
  line-height: 28px;
  -webkit-line-clamp: 1;
  position: relative;
  margin-top: -1px;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.screen .frame-6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  opacity: 0.3;
}

.screen .frame-7 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  opacity: 0.3;
}

.screen .pagenation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 16px 0px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.screen .selected {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 16px;
  position: relative;
  flex: 0 0 auto;
  background-color: #15181e;
  border-radius: 8px;
}

.screen .user-text-4 {
  width: fit-content;
  font-family: "Pretendard-Bold", Helvetica;
  font-weight: 700;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  position: relative;
  margin-top: -1px;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.screen .default {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 16px;
  position: relative;
  flex: 0 0 auto;
  border-radius: 8px;
}

.screen .user-text-5 {
  width: fit-content;
  font-family: "Pretendard-Medium", Helvetica;
  font-weight: 500;
  color: #717680;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  position: relative;
  margin-top: -1px;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.screen .arrow-r {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
  border-radius: 8px;
}

.screen .chevron-right-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-space-1);
  padding: 12px;
  position: relative;
  flex: 0 0 auto;
  border-radius: var(--borders-rounded-small);
}

.screen .frame-wrapper {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 16px;
  position: relative;
  width: 100%;
  background-color: #ffffff;
}

.screen .profile-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  gap: 95px;
  position: relative;
}

.screen .profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.screen .title-3 {
  margin-top: -1px;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: var(--core-slate-800);
  font-size: 18px;
  text-align: center;
  line-height: 26px;
  position: relative;
  width: fit-content;
  letter-spacing: 0;
  white-space: nowrap;
}

.screen .title-4 {
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: 400;
  color: #717680;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  width: fit-content;
  letter-spacing: 0;
  white-space: nowrap;
}

</style>
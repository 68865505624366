<template>
  <div class="screen">
    <div class="div">
      <div class="sheet">
        <div class="body">
          <div class="contents-information">
            <div class="icon">
              <div class="chevron-down"><img class="subtract" src="/img/subtract-4.svg" /></div>
            </div>
            <div class="content">
              <p class="text-wrapper">
                현재 공구 진행은 Top 브랜드에게만 제공중이며, 젤러팀 검토 후 공구를 진행합니다.
              </p>
            </div>
          </div>
        </div>
        <div class="option">
          <div class="sheet-option">
            <div class="outlined-textfield">
              <div class="label"><div class="label-2">회사명</div></div>
              <OutlinedTextfield
                  class="outlined-textfield-instance"
                  content="filled-text"
                  v-model="companyName"
                  size="large"
                  state="default"
                  input-type="text"
                  userTextClassName="outlined-textfield-2"
              />
            </div>
            <div class="outlined-textfield">
              <div class="label"><div class="label-2">브랜드명</div></div>
              <OutlinedTextfield
                  class="outlined-textfield-instance"
                  content="filled-text"
                  v-model="brandName"
                  size="large"
                  state="default"
                  input-type="text"
                  userTextClassName="outlined-textfield-2"
              />
            </div>
            <div class="outlined-textfield-2">
              <div class="label"><div class="label-2">분야</div></div>
              <div class="select">
                <select v-model="businessArea" class="assets-select-field">
                  <option disabled value="">선택해 주세요</option>
                  <option value="cosmetics">화장품</option>
                  <option value="fashion">패션잡화</option>
                  <option value="health">건강기능식품</option>
                  <option value="baby">육아용품</option>
                  <option value="pet">펫용품</option>
                  <option value="household">생활용품</option>
                </select>
              </div>
            </div>
            <div class="outlined-textfield-2">
              <div class="label"><div class="label-2">상품 링크</div></div>
              <OutlinedTextfield
                  class="outlined-textfield-instance"
                  content="filled-text"
                  v-model="productLink"
                  size="large"
                  state="default"
                  input-type="text"
                  userTextClassName="outlined-textfield-2"
              />
            </div>
          </div>
          <div class="rectangle"></div>
          <div class="sheet-option">
            <div class="label"><div class="label-2">연락처</div></div>
            <div class="outlined-textfield">
              <div class="label"><div class="label-3">휴대폰번호</div></div>
              <OutlinedTextfield
                  class="outlined-textfield-instance"
                  content="filled-text"
                  v-model="phoneNumber"
                  size="large"
                  state="default"
                  input-type="text"
                  userTextClassName="outlined-textfield-2"
              />
            </div>
            <div class="outlined-textfield">
              <div class="label"><div class="label-3">이메일</div></div>
              <OutlinedTextfield
                  class="outlined-textfield-instance"
                  content="filled-text"
                  v-model="email"
                  size="large"
                  state="default"
                  input-type="text"
                  userTextClassName="outlined-textfield-2"
              />
            </div>
          </div>
          <div class="rectangle"></div>
          <div class="sheet-option-2">
            <CheckBox2 v-model="userAgreements">
              <div class="label-4">개인정보 수집 동의</div>
            </CheckBox2>
            <p class="p">(필수) 개인정보 수집 이용 동의</p>
          </div>
        </div>
      </div>
      <div class="frame-6">
        <button class="CTA">
          <div class="primary-button-wrapper">
            <PrimaryButton
                :fullWidth="true"
                :rounded="true"
                size="large"
                state="default"
                text="문의하기"
                :disabled="!isFormValid"
                @click="handleSubmit"
            />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import axios from 'axios';
import { API_BASE_URL } from "@/config";
import PrimaryButton from "@/components/PrimaryButton.vue";
import OutlinedTextfield from "@/components/OutlinedTextfield.vue";
import CheckBox2 from "@/components/CheckBox2.vue";
import Footer from "@/screens/customer/Footer.vue";

export default defineComponent({
  name: "BrandAskingForm",
  components: {
    OutlinedTextfield,
    PrimaryButton,
    CheckBox2,
    Footer,
  },
  setup(props, { emit }) {
    const companyName = ref("");
    const businessArea = ref("");
    const brandName = ref("");
    const productLink = ref("");
    const phoneNumber = ref("");
    const email = ref("");
    const userAgreements = ref(false);

    // 모든 필드가 입력되었는지 확인하는 computed property
    const isFormValid = computed(() => {
      return (
          companyName.value &&
          businessArea.value &&
          brandName.value &&
          productLink.value &&
          phoneNumber.value &&
          email.value &&
          userAgreements.value
      );
    });

    const handleSubmit = async () => {
      console.log('Submit button clicked');

      if (!isFormValid.value) return;

      const payload = {
        companyName: companyName.value,
        businessArea: businessArea.value,
        brandName: brandName.value,
        productLink: productLink.value,
        phoneNumber: phoneNumber.value,
        email: email.value,
      };

      try {
        const response = await axios.post(`${API_BASE_URL}/api/brand/ask`, payload);

        if (response.status === 200) {
          alert('문의가 등록되었습니다. 검토 후 남겨주신 연락처로 안내드리겠습니다. ');
          // 약간의 delay 추가
          await new Promise((resolve) => setTimeout(resolve, 200));
          // 필드 초기화
          companyName.value = "";
          businessArea.value = "";
          brandName.value = "";
          productLink.value = "";
          phoneNumber.value = "";
          email.value = "";
          userAgreements.value = false;

          emit('close-sheet');
        } else {
          alert('문의 등록 중 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.');
        }
      } catch (error) {
        console.error('Error submitting inquiry:', error);
        alert('문의 등록 중 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.');
      }
    };

    return {
      companyName,
      businessArea,
      brandName,
      productLink,
      phoneNumber,
      email,
      userAgreements,
      handleSubmit,
      isFormValid, // 추가된 computed property
    };
  },
});
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.screen {
  background-color: #f8f9fb;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
}

.screen .div {
  background-color: #f8f9fb;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  overflow-x: hidden;
}

@media (min-width: 600px) {
  .screen .div {
    width: 100%;
  }
}

.screen .sheet {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding: 24px var(--size-space-0) var(--size-space-0) var(--size-space-0);
  position: relative;
  background-color: #ffffff;
  border-radius: var(--shape-corner-large) var(--shape-corner-large) 0px 0px;
  gap: var(--size-space-0);
}

.screen .body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 0px 16px;
  width: 100%;
  flex: 0 0 auto;
  position: relative;
  align-self: stretch;
}

.screen .contents-information {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-space-2);
  padding: var(--spacing-space-3) var(--spacing-space-4) var(--spacing-space-3) var(--spacing-space-4);
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #f5ffdb;
  border-radius: 8px;
}

.screen .icon {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 0px;
  position: relative;
  align-self: stretch;
  flex: 0 0 auto;
}

.screen .chevron-down {
  position: relative;
  width: 24px; /* 아이콘 크기 조정 */
  height: 24px; /* 아이콘 크기 조정 */
  margin-left: auto; /* 아이콘을 가장 오른쪽으로 배치 */
}

.screen .subtract {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 1px;
  left: 1px;
}

.screen .content {
  display: flex;
  gap: 8px;
  padding: 2px 0px;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.screen .text-wrapper {
  margin-top: -1px;
  font-family: "Pretendard-Regular", Helvetica;
  font-weight: var(--body-medium-font-weight);
  color: var(--colors-text);
  font-size: var(--body-medium-font-size);
  letter-spacing: var(--body-medium-letter-spacing);
  line-height: var(--body-medium-line-height);
  position: relative;
  align-self: stretch;
  font-style: var(--body-medium-font-style);
}

.screen .option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px var(--size-space-200) 0px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.screen .sheet-option {
  gap: var(--size-space-300);
  padding: var(--size-space-400) var(--size-space-400) 16px var(--size-space-400);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.screen .outlined-textfield {
  display: flex;
  flex-direction: column;
  min-width: 80px;
  align-items: flex-start;
  gap: var(--spacing-space-15);
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  border-radius: var(--borders-rounded-medium);
}

.screen .label {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-space-1);
  position: relative;
  flex: 0 0 auto;
}

.screen .label-2 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: #15181e;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.screen .outlined-textfield-2 {
  display: flex;
  flex-direction: column;
  min-width: 80px;
  align-items: flex-start;
  gap: var(--spacing-space-15);
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.screen .select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-space-15);
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  border-radius: var(--shape-corner-small);
  border: 1px solid;
  border-color: #d9d9d9;
  cursor: pointer;
  background-color: #ffffff;
}

.screen .assets-select-field {
  display: flex;
  height: 48px;
  align-items: center;
  gap: var(--spacing-space-2);
  padding: 0px 12px;
  position: relative;
  align-self: stretch;
  width: 100%;
}

.screen .chevron-down {
  width: 24px;
  height: 24px;
  margin-left: auto;
}

.screen .rectangle {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 6px;
  background-color: #f8f9fb;
}

.screen .label-3 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-weight: var(--label-medium-font-weight);
  color: #15181e;
  font-size: var(--label-medium-font-size);
  letter-spacing: var(--label-medium-letter-spacing);
  line-height: var(--label-medium-line-height);
  white-space: nowrap;
  font-style: var(--label-medium-font-style);
}

.screen .sheet-option-2 {
  gap: var(--size-space-200);
  padding: var(--size-space-400) var(--size-space-400) var(--size-space-200) var(--size-space-400);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.screen .label-4 {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: var(--colors-text);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.screen .p {
  width: fit-content;
  font-family: "Pretendard-Medium", Helvetica;
  font-weight: 500;
  color: var(--colors-text-sub);
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  position: relative;
  letter-spacing: 0;
}

.screen .frame-6 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  position: relative;
}

.screen .CTA {
  all: unset;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: var(--size-space-400);
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: #d9d9d9;
}

.screen .primary-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.screen .top-bar-name {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  position: relative;
  background-color: var(--x-semantic-lightsurface);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #d9d9d9;
}

.screen .title {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: var(--core-slate-800);
  font-size: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap;
}

.screen .select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-space-15);
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  border-radius: var(--borders-rounded-medium); /* 둥근 모서리 적용 */
  border: 1px solid;
  border-color: #d9d9d9;
  cursor: pointer;
  background-color: #ffffff;
  overflow: hidden; /* select 박스에 둥근 모서리가 제대로 적용되도록 설정 */
}

.screen .select select {
  width: 100%;
  padding: 12px;
  border: none;
  outline: none;
  border-radius: var(--borders-rounded-medium); /* 둥근 모서리 적용 */
  -webkit-appearance: none; /* 기본 스타일 제거 */
  -moz-appearance: none; /* 기본 스타일 제거 */
  appearance: none; /* 기본 스타일 제거 */
  background-color: transparent;
  font-weight: var(--body-large-font-weight);
  color: var(--colors-text);
  font-size: var(--body-large-font-size);
  line-height: var(--body-large-line-height);
}

.screen .assets-select-field {
  display: flex;
  height: 48px;
  align-items: center;
  gap: var(--spacing-space-2);
  padding: 0px 12px;
  position: relative;
  align-self: stretch;
  width: 100%;
}

.screen .chevron-down {
  width: 24px;
  height: 24px;
  margin-left: auto;
}

.screen .custom-checkbox:checked + .custom-checkbox-label .assets-check {
  background-color: var(--colors-action-primary);
  border-color: var(--colors-action-primary);
}

.screen .custom-checkbox:checked + .custom-checkbox-label .assets-check::after {
  display: block; /* 체크 상태에서 이미지 표시 */
}
</style>

<template>
  <div class="screen">
    <div class="div">
      <div class="group">
        <div class="master-circle">
          <div class="icon-wrapper"><img class="img" src="/img/icon-4.svg" alt=""/></div>
        </div>
        <div class="title">
          <div class="text-wrapper">판매가 종료됐어요</div>
          <div class="user-text">이용해주셔서 감사합니다.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.screen {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh; /* 전체 화면 중앙에 정렬 */
}

.screen .div {
  background-color: #ffffff;
  width: 360px;
  height: 775px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.screen .group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.screen .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 16px; /* 아이콘과 텍스트 사이의 간격 */
}

.screen .text-wrapper {
  font-family: "Pretendard-Bold", Helvetica;
  font-weight: 700;
  color: #1d2027;
  font-size: 20px;
  text-align: center;
  line-height: 24px;
}

.screen .user-text {
  font-family: "Pretendard-Medium", Helvetica;
  font-weight: 500;
  color: #717680;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
}

.screen .master-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
}

.screen .icon-wrapper {
  width: 56px;
  height: 56px;
  background-image: url('@/assets/subtract.svg');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.screen .img {
  width: 24px; /* 아이콘 크기 조정 */
  height: 24px;
}

</style>

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slide = _resolveComponent("Slide")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_carousel = _resolveComponent("carousel")!

  return (_openBlock(), _createBlock(_component_carousel, {
    "items-to-show": 1,
    "wrap-around": true
  }, {
    addons: _withCtx(() => [
      _createVNode(_component_pagination, { class: "custom-pagination" })
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deal.dealImages.list, (image, index) => {
        return (_openBlock(), _createBlock(_component_Slide, { key: index }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: image.url,
              alt: image.description || 'Carousel Image',
              class: "carousel-image"
            }, null, 8, _hoisted_1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}
<template>
  <div class="screen">
    <div class="content">
      <div class="top-logo">
        <img class="vector" alt="Vector" src="/img/vector.svg" />
      </div>
      <div class="frame">
        <div class="inpur-group">
          <Label bold class="label-instance" divClassName="label-4" text="휴대폰번호" type="primary" />
          <div class="view">
            <OutlinedTextfield
                class="outlined-textfield-instance"
                content="filled-text"
                userTextClassName="outlined-textfield-2"
                placeholderText="010"
                size="large"
                state="focus"
                v-model="phoneNumber"
                inputType="tel"
            />
            <PrimaryButton
                autoLayoutButtonClassName="primary-button-2"
                class="primary-button-instance-small"
                :fullWidth="false"
                icon="none"
                labelClassName="primary-button-3"
                :rounded="false"
                size="large"
                state="default"
                text="인증"
                :tonal="false"
                @click="handleNumberAuth"
            />
          </div>
          <Label bold class="label-instance" divClassName="label-4" text="인증번호" type="primary" />
          <OutlinedTextfield
              class="outlined-textfield-4"
              content="filled-text"
              :showPlaceholderText="false"
              size="large"
              state="default"
              userTextClassName="outlined-textfield-3"
              v-model="authNumber"
              inputType="number"
          />
        </div>
        <div class="primary-button-wrapper">
          <PrimaryButton
              autoLayoutButtonClassName="design-component-instance-node"
              className="primary-button-instance"
              :fullWidth="true"
              icon="none"
              labelClassName="primary-button-2"
              :rounded="true"
              size="large"
              state="default"
              text="로그인"
              :tonal="true"
              @click="handleAuth"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import OutlinedTextfield from "@/components/OutlinedTextfield.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import Label from "@/components/Label.vue";
import { API_BASE_URL } from "@/config"; // 환경 변수

const phoneNumber = ref('');
const authNumber = ref('');
const router = useRouter();

const handleAuth = async () => {
  if (phoneNumber.value && authNumber.value) {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/sellers/me/auth`, {
        phoneNumber: phoneNumber.value,
        authNumber: authNumber.value
      });

      if (response.data && response.data.jwt) {
        // orderId 별로 JWT를 localStorage에 저장
        const sellerId = response.data.id;
        localStorage.setItem(`jwt_seller_${sellerId}`, response.data.jwt);
        console.log('인증 성공:', response.data);
        await router.push({ name: 'SellerSalesStat', query: { sellerId: sellerId } });
      } else {
        alert('인증에 실패했습니다. 다시 시도해 주세요.');
      }
    } catch (error) {
      console.error('인증 오류:', error);
      alert('휴대폰번호와 인증번호를 정확히 입력해 주세요.');
    }
  } else {
    alert('휴대폰번호와 인증번호를 정확히 입력해 주세요.');
  }
};

const handleNumberAuth = async () => {
  if (phoneNumber.value.length !== 11) {
    alert('휴대폰번호는 11자리여야 합니다.');
    return;
  }

  try {
    const response = await axios.post(`${API_BASE_URL}/api/sellers/send-auth-number`, {
      phoneNumber: phoneNumber.value,
    });

    if (response.status === 200) {
      alert('인증문자가 발송되었습니다.');
    } else {
      alert('인증문자 발송에 실패했습니다. 다시 시도해 주세요.');
    }
  } catch (error) {
    console.error('인증문자 발송 오류:', error);
    alert('인증문자 발송 중 오류가 발생했습니다. 다시 시도해 주세요.');
  }
};
</script>

<style scoped>
.screen {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  padding-top: 0;
}

.screen .content {
  background-color: #ffffff;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.screen .top-logo {
  align-items: center;
  background-color: var(--x-semantic-lightsurface);
  display: flex;
  justify-content: center;
  padding: 10px 16px;
  width: 100%;
}

.vector {
  width: 97px;
  aspect-ratio: 3.46;
  object-fit: contain;
  margin-top: 40px;
}

.frame {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 16px 10px;
  width: 100%;
  max-width: 600px;
}

.screen .inpur-group {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.view {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  gap: 8px;
  position: relative;
  width: 100%;
}

.outlined-textfield-instance {
  flex: 1 !important;
  flex-grow: 1 !important;
  min-width: 80px !important;
  width: unset !important;
}

.primary-button-instance-small {
  display: flex !important;
  width: 80px !important;
}

.outlined-textfield-4 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  min-width: 80px !important;
  width: 100% !important;
}

.primary-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.label-instance {
  color: #15181e !important;
  font-family: "Pretendard-SemiBold", Helvetica !important;
  font-size: 14px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
}
</style>
<template>
  <div v-if="deal && !isDealClosed" class="screen">
    <div class="content">
      <DealProductInfo :deal="deal" />
      <Footer />
    </div>
    <component :is="currentBottom"
               :deal="deal"
               :selectedOptions="selectedOptions"
               :orderId="orderId"
               @switch-to-options="switchToOptions"
               @switch-to-basic="switchToBasic"
               @switch-to-order="switchToOrder"
               @switch-to-success="switchToSuccess" />
  </div>
  <DealClosed v-else-if="deal" />
  <div v-else> </div>
</template>


<script lang="ts">
import {computed, defineComponent, onBeforeUnmount, onMounted, ref, watch} from "vue";
import {useRoute} from 'vue-router';
import axios from 'axios';
import DealProductInfo from "@/screens/customer/DealProductInfo.vue";
import Footer from "@/screens/customer/Footer.vue";
import BottomBasic from "@/screens/customer/BottomBasic.vue";
import BottomOptions from "@/screens/customer/BottomOptions.vue";
import BottomOrder from "@/screens/customer/BottomOrder.vue";
import BottomSuccess from "@/screens/customer/BottomSuccess.vue";
import DealClosed from "@/screens/customer/DealClosed.vue";
import {DealOption} from "@/types/DealOption";
import {Deal} from "@/types/Deal";
import {API_BASE_URL} from "@/config"; // 환경 변수

export default defineComponent({
  name: "DealDetail",
  components: {
    DealProductInfo,
    Footer,
    BottomBasic,
    BottomOptions,
    BottomOrder,
    BottomSuccess,
    DealClosed, // DealClosed 컴포넌트 추가
  },
  setup() {
    const currentBottom = ref("BottomBasic");
    const route = useRoute();
    const deal = ref<Deal | null>(null);
    const selectedOptions = ref<DealOption[]>([]);
    const orderId = ref<string | null>(null);

    const fetchDeal = async (dealId: string) => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/deals/${dealId}`);
        deal.value = response.data as Deal;

        if (deal.value && deal.value.name) {
          document.title = `${deal.value.name} - 젤러 | 나만의 공구 스토어`;
        }
      } catch (error) {
        console.error('Error fetching deal:', error);
      }
    };

    const isDealClosed = computed(() => {
      if (!deal.value) return false; // deal이 null일 경우 false 반환 (즉, 아직 로딩 중)

      // 종료 일자 체크
      const now = new Date().getTime(); // 현재 시간의 타임스탬프
      const endDate = new Date(Math.floor(deal.value.endDate * 1000)).getTime(); // endDate를 타임스탬프 형식으로 변환
      if (now > endDate) {
        console.log('Deal is closed:', new Date(endDate).toLocaleString('ko-KR', {timeZone: 'Asia/Seoul'}));
        return true;
      }

      // 옵션 재고 체크
      const checkOption = deal.value.dealOptions?.every(option => option.stockQuantity <= 0) ?? true;
      console.log('Deal option is closed:', checkOption);
      return checkOption;
    });


    const switchToOptions = () => {
      currentBottom.value = "BottomOptions";
      history.pushState(null, ""); // BottomSheet 를 열었을 때 히스토리에 추가
    };

    const switchToBasic = () => {
      currentBottom.value = "BottomBasic";
    };

    const switchToOrder = (options: DealOption[]) => {
      selectedOptions.value = options; // 선택된 옵션을 설정
      currentBottom.value = "BottomOrder";
      history.pushState(null, ""); // BottomSheet 를 열었을 때 히스토리에 추가
    };

    const switchToSuccess = () => {
      currentBottom.value = "BottomSuccess";
      history.pushState(null, ""); // BottomSheet 를 열었을 때 히스토리에 추가
    };

    const handlePopState = () => {
      if (currentBottom.value !== "BottomBasic") {
        switchToBasic(); // BottomSheet를 닫고 기본 상태로 돌아갑니다.
      } else {
        history.back(); // 이전 화면으로 이동합니다.
      }
    };

    onMounted(() => {
      const dealId = route.params.dealId as string;
      fetchDeal(dealId);

      const urlParams = new URLSearchParams(window.location.search);
      const status = urlParams.get("status");
      const orderIdParam = urlParams.get("orderId");
      const tk = urlParams.get("tk");

      orderId.value = orderIdParam || orderId.value;

      // tk 파라미터가 존재하는지 확인
      if (tk) {
        try {
          // Base64로 디코딩
          const decodedData = decodeURIComponent(escape(atob(tk))); // 한글 처리 포함

          // 데이터 분리
          const [timestamp, success, tkOrderId, message] = decodedData.split("|");

          // 현재 시간과 비교
          const now = new Date();
          const tkTime = new Date(timestamp);
          orderId.value = tkOrderId;

          // 현재 시각에서 10분 이내인지 확인
          const timeDifference = now.getTime() - tkTime.getTime();
          const isValidTime = timeDifference <= 10 * 60 * 1000; // 10분 이내

          if (isValidTime) {
            if (success === "true") {
              switchToSuccess();
            } else if (success === "false") {
              const messageText = message && message !== "null" ? message : "주문 처리 중 문제가 발생하였습니다. 승인완료된 결제건은 자동 취소 처리될 예정입니다. 계속될 경우 카톡채널 @젤러 로 문의해 주세요.";
              // message가 null이거나 "null"이 아니면 메시지 출력, 그 외는 기본메세지 출력
              alert(messageText);
            }
          } else {
            // 시간 초과된 경우 처리
            console.error("tk 파라미터가 유효하지 않습니다.");
          }
        } catch (error) {
          console.error("tk 파라미터를 디코딩하는 중 오류 발생:", error);
        }
      } else if (status === "success") {
        // 기존 status 처리
        switchToSuccess();
      } else {
        switchToBasic();
      }

      // PopState 이벤트 리스너 등록
      window.addEventListener("popstate", handlePopState);

      // 아이폰에 카카오 결제를 브라우저 백으로 취소한 경우 나이스페이 레이어가 남아 있는 경우가 있어 제거
      window.addEventListener('pageshow', function (event) {
        const newDivLayer = document.getElementById('newDivLayer');
        if (newDivLayer) {
          console.log('pageshow newDivLayer exist - deleted', newDivLayer);
          newDivLayer.style.display = 'none'; // 숨기기
        }
      });
    });

    // Unmount 시 이벤트 리스너 제거
    onBeforeUnmount(() => {
      window.removeEventListener("popstate", handlePopState);
    });

    // currentBottom 값에 대한 watch 추가 - 아이폰12 에서 BottomSheet 오픈 후에도 스크롤이 되는 문제 해결
    watch(currentBottom, (newVal, oldVal) => {
      if (newVal === "BottomOptions" || newVal === "BottomOrder") {
        document.body.style.overflow = 'hidden'; // 스크롤 비활성화
      } else {
        document.body.style.overflow = 'auto'; // 스크롤 활성화
      }
    });

    return {
      currentBottom,
      deal,
      selectedOptions,
      orderId, // orderId 추가
      switchToOptions,
      switchToBasic,
      switchToOrder,
      switchToSuccess,
      isDealClosed, // isDealClosed 컴퓨티드 프로퍼티 추가
    };
  },
});
</script>





<style scoped>
* {
  box-sizing: border-box;
}

.screen {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
}

.content {
  background-color: #ffffff;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  overflow-x: hidden;
  padding-bottom: 100px;
}

@media (min-width: 600px) {
  .content {
    width: 100%;
  }
}
</style>
<template>
  <div>
    <!-- 텍스트 링크 -->
    <p class="p" @click="openLayer('terms')">(필수) 이용약관</p>
    <p class="p" @click="openLayer('privacy')">(필수) 개인정보처리방침</p>
    <p class="p" @click="openLayer('refund')">(필수) 교환환불 규정</p>

    <!-- 레이어 -->
    <Teleport to="body">
      <div v-if="showLayer" class="layer-overlay">
        <div class="layer-content">
          <!-- 타이틀 및 닫기 버튼 -->
          <div class="layer-header">
            <img class="title__x" src="/img/x-1.png" alt="X" />
            <div class="title">{{ layerTitle }}</div>
            <img class="close" src="/img/x.svg" @click="closeLayer" alt="닫기" />
          </div>

          <!-- 각 문서 내용 -->
          <div class="layer-body">
            <component :is="currentComponent"></component>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Terms from "@/screens/customer/Terms.vue";
import PrivacyPolicy from "@/screens/customer/PrivacyPolicy.vue";
import RefundPolicy from "@/screens/customer/RefundPolicy.vue";

const showLayer = ref(false);
const layerTitle = ref("");
const currentComponent = ref();

// 레이어를 여는 함수
const openLayer = (type: string) => {
  showLayer.value = true;
  switch (type) {
    case "terms":
      layerTitle.value = "이용약관";
      currentComponent.value = Terms;
      break;
    case "privacy":
      layerTitle.value = "개인정보처리방침";
      currentComponent.value = PrivacyPolicy;
      break;
    case "refund":
      layerTitle.value = "교환환불 규정";
      currentComponent.value = RefundPolicy;
      break;
  }
};

// 레이어를 닫는 함수
const closeLayer = () => {
  showLayer.value = false;
};
</script>

<style scoped>
.p {
  color: var(--colors-text-sub);
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px; /* 간격을 넓혀 클릭하기 쉽게 조정 */
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
  text-decoration: underline;
}

.layer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(29, 26, 26, 0.5);
  -webkit-transform: translate3d(0,0,0);
  z-index: 9999999; /* z-index 최상단으로 설정 */
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}

.layer-content {
  background-color: white;
  width: 100%;
  max-width: 600px;
  z-index: 1000000;
  -webkit-transform: translate3d(0,0,0);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.layer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000000;
  -webkit-transform: translate3d(0,0,0);
  padding: 10px 16px;
  background-color: var(--x-semantic-lightsurface);
  border-bottom: 1px solid #d9d9d9;
  flex-shrink: 0;
}

.layer-header .title__x,
.layer-header .close {
  width: 24px;
  height: 24px;
}

.layer-header .title {
  font-family: "Pretendard-SemiBold", Helvetica;
  font-weight: 600;
  color: var(--core-slate-800);
  font-size: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: 28px;
}

.layer-body {
  padding: 16px;
  overflow-y: auto; /* Body 부분에만 스크롤 */
  flex-grow: 1;
}
</style>
